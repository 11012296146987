<template>
  <div>
    <v-breadcrumbs
      class="pt-3 pl-7"
      :items="[{ text: 'Offertes', exact: true,  to: { name: 'offers' } }, { text: offer.offer_number }]"
    >
    </v-breadcrumbs>
    <v-row
      class="mt-0"
    >
      <v-col
        cols="12"
        md="9"
      >
        <div
          v-if="offer.id && !offer.is_active"
          class="white mx-3"
        >
          <v-alert
            type="warning"
            outlined
            text
            border="left"
            icon="fas fa-exclamation-triangle"
          >Let op! U bent een versie uit het verleden aan het bekijken.</v-alert>
        </div>
        <v-card
          flat
          class="mx-3 pa-6"
        >
          <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
            <v-card-title>Offerte #{{ offer.offer_number }}</v-card-title>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              tile
              color="primary"
              class="my-1"
              @click="$refs.confirmCreateDuplicateOffer.show()"
            ><v-icon small left>fas fa-layer-plus</v-icon>Offerte dupliceren</v-btn>
            <v-btn
              outlined
              tile
              color="primary"
              class="ml-3 my-1"
              @click="downloadOfferDialog = true"
            ><v-icon small left>fas fa-download</v-icon>Offerte</v-btn>
            <v-btn
              outlined
              tile
              color="primary"
              class="ml-3 my-1"
              :disabled="!offer.is_active_editable"
              @click="$refs.confirmCreateNewVersion.show()"
            ><v-icon small left>fas fa-layer-plus</v-icon>Nieuwe versie</v-btn>
            <v-btn
              :disabled="!offer.is_editable"
              outlined
              tile
              color="primary"
              class="ml-3 my-1"
              @click="mailOfferDialog = true"
            ><v-icon small left>fas fa-paper-plane</v-icon>Verstuur offerte</v-btn>
          </v-row>

          <offer-lines-table></offer-lines-table>
        </v-card>

      </v-col>
      <v-col
        cols="12"
        md="3"
        class="px-6 px-md-3"
      >

        <offer-info-card></offer-info-card>

        <offer-history-card class="mt-5"></offer-history-card>

      </v-col>
    </v-row>

    <ConfirmDialog
      ref="confirmCreateNewVersion"
      title="Nieuwe versie"
      labelConfirm="Aanmaken"
      labelConfirmColor="primary"
      @confirm="createNewVersionClick"
    ></ConfirmDialog>

    <ConfirmDialog
      ref="confirmCreateDuplicateOffer"
      title="Offerte dupliceren"
      labelConfirm="Dupliceren"
      labelConfirmColor="primary"
      @confirm="createDuplicateOfferClick"
    ></ConfirmDialog>

    <v-dialog
      v-model="downloadOfferDialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Offerte</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="downloadOfferDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">

          <v-form @submit.prevent
            class="mt-6"
          >

            <v-checkbox
              v-model="offer.offer_status_id"
              :true-value="OFFER_STATUS_OFFERED"
              :false-value="oldOffer.offer_status_id"
              label="Status: Uitgebracht"
            ></v-checkbox>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="downloadOfferDialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="downloadOfferClick(false)"
          >Openen</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="downloadOfferClick(true)"
          >Download</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <mail-offer-dialog
      v-model="mailOfferDialog"
      max-width="700"
      persistent
      @update="updateOffer()"
    ></mail-offer-dialog>

  </div>
</template>

<script>
import { inject, provide, ref, watch } from '@vue/composition-api'
import { formatDate } from '@/utils'
import ConfirmDialog from '@/components/ConfirmDialog'
import OfferInfoCard from './offer/InfoCard'
import OfferHistoryCard from './offer/HistoryCard'
import OfferLinesTable from './offer/OfferLinesTable'
import MailOfferDialog from './offer/MailOfferDialog.vue'
import { useOffer, OFFER_STATUS_OFFERED, OFFER_STATUS_ORDERED } from '@/utils'

export default {
  components: {
    ConfirmDialog,
    OfferInfoCard,
    OfferHistoryCard,
    OfferLinesTable,
    MailOfferDialog,
  },
  setup(props, context) {
    const api = inject('api')
    const loader = inject('loader')

    provide('offerStatuses', api.resource('offer-statuses').collection({
      params: {
        filter: {
          id: { neq: OFFER_STATUS_ORDERED },
        },
        sort: 'name',
      },
    }).all())
    provide('offerRejectReasons', api.resource('offer-reject-reasons').collection().all())
    provide('mailTemplates', api.resource('mail-templates').collection().all())
    provide('users', api.resource('users').collection().all())

    // offerModel
    const offerModel = api.resource('offers').model({
      params: {
        expand: 'customer,user,served_user,offer_status,versions,prices',
        fields: '*,versions.id,versions.offer_number'
      },
    })
    provide('offerModel', offerModel)
    loader.bindRef(offerModel.isSaving)

    // offerLineCollection
    const offerLineCollection = api.resource('offer-lines').collection({
      params: {
        expand: 'offer_line_status',
        sort: 'sort_index',
      },
      itemsPerPage: null,
    })
    provide('offerLineCollection', offerLineCollection)

    const { isEditable, isActive, isActiveEditable } = useOffer(offerModel)
    const { attributes: offer, oldAttributes: oldOffer } = offerModel

    const downloadOfferDialog = ref(false)
    const mailOfferDialog = ref(false)
    // const mailOfferModel = api.resource().model()

    // console.log(mailOfferModel)

    watch(() => context.root.$route.params, params => {
      offerModel.find(params.id).catch(() => {
        context.root.$router.push({
          name: 'offers',
        })
      })
      offerLineCollection.setFilter('offer_id', params.id)
      offerLineCollection.search()
    }, { immediate: true })

    function createNewVersionClick() {
      api.resource('offers').post(offerModel.getAttribute('id') + '/create-new-version').then(response => {
        context.root.$router.push({
          name: 'offer',
          params: { id: response.data.id },
        })
      })
    }

    function createDuplicateOfferClick() {
      api.resource('offers').post(offerModel.getAttribute('id') + '/create-duplicate-offer').then(response => {
        context.root.$router.push({
          name: 'offer',
          params: { id: response.data.id },
        })
      })
    }
    

    function downloadOfferClick(download = true) {
      offerModel.save().then(() => {
        loader.show()
        api.resource('offers').get(`${offerModel.getAttribute('id')}/pdf`, {
          responseType: 'blob',
        }).then(({ data }) => {
          const url = window.URL.createObjectURL(data)
          let title = 'Offerte #' + offerModel.getAttribute('offer_number')
          if (!download) {
            let w = window.open(url, '_blank')
            let i = setInterval(() => { w.document.title = title }, 100)
            setTimeout(() => { clearInterval(i) }, 5000)
          } else {
            const link = document.createElement('a')
            link.href = url
            link.download = title
            link.click()
          }
          // remove after x seconds
          setTimeout(() => { URL.revokeObjectURL(url) }, 6000)
        }).finally(() => {
          loader.hide()
          downloadOfferDialog.value = false
        })
      })
    }

    function updateOffer() {
      offerModel.refresh()
    }

    return {
      isEditable,
      isActive,
      isActiveEditable,

      offer,
      oldOffer,

      formatDate,
      createNewVersionClick,
      createDuplicateOfferClick,
      downloadOfferClick,
      updateOffer,
      
      downloadOfferDialog,
      mailOfferDialog,
      OFFER_STATUS_OFFERED,
    }
  },
}
</script>

