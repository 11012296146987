<template>
  <v-input hide-details>
    <v-card
      v-if="modelValue"
      outlined
      min-width="50%"
      @drop.prevent="dropFile"
      @dragenter.prevent
      @dragover.prevent
      v-bind="$attrs"
    >
      <v-btn
        icon
        small
        color="error"
        absolute
        style="right:0;z-index:2"
        @click.stop="modelValue = null"
        :disabled="disabled"
      ><v-icon small>fas fa-times</v-icon></v-btn>
      <v-img
        :src="modelValue"
        height="200px"
        class="clickable"
        @click="dialog = true"
      ></v-img>
    </v-card>
    <v-card
      v-else
      outlined
      min-width="50%"
      min-height="200px"
      @drop.prevent="dropFile"
      @dragenter.prevent
      @dragover.prevent
    >
      <v-card-text
        class="text-center mt-8"
      >
        <p v-if="!disabled">Drop foto hier, of <a
        @click="$refs.fileBrowser.click()"
        >bladeren</a></p>
        <v-icon x-large>fas fa-image</v-icon>
      </v-card-text>
      <input
        ref="fileBrowser"
        type="file"
        @change="browseFile"
        hidden
      />
    </v-card>
    <v-dialog
      v-model="dialog"
      width="75vw"
    >
      <v-card
        color="white"
      >
        <v-btn
          icon
          absolute
          top
          right
          style="z-index:2"
          @click.stop="dialog= false"
        ><v-icon>fas fa-times</v-icon></v-btn>
        <v-img
          width="75vw"
          max-height="85vh"
          contain
          :src="modelValue"
        ></v-img>
      </v-card>
    </v-dialog>
  </v-input>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { readFileAsDataUrl } from '@/utils'

export default {
  props: {
    label: String,
    value: {},
    disabled: Boolean,
  },
  setup(props, context) {

    const modelValue = ref(props.value)
    const dialog = ref(false)

    watch(() => props.value, val => {
      modelValue.value = val
    })

    watch(modelValue, val => {
      context.emit('input', val)
    })

    function dropFile(e) {
      if (props.disabled) return;
      const files = Array.from(e.dataTransfer.files)
      handleFile(files[0])
    }

    function browseFile(e) {
      if (props.disabled) return;
      const files = e.target.files
      handleFile(files[0])
    }

    function handleFile(file) {
      readFileAsDataUrl(file).then(url => {

        let img = new Image()

        let maxWidth = 1440
        let maxHeight = 800

        img.onload = function() {

          // resize
          if (img.width > maxWidth || img.height > maxHeight) {

            let canvas = document.createElement('canvas')
            let ctx = canvas.getContext('2d')

            let scaleX = maxWidth / img.width
            let scaleY = maxHeight / img.height
            
            if (scaleX < scaleY) {
              canvas.width = scaleX * img.width
              canvas.height = canvas.width * (img.height / img.width)
            } else {
              canvas.height = scaleY * img.height
              canvas.width = canvas.height * (img.width / img.height)
            }

            ctx.drawImage(img, 0, 0, canvas.width, canvas.height)

            // console.log('image scale', img.width, img.height, canvas.width, canvas.height)

            url = canvas.toDataURL('image/jpg', 0.8)
          }

          modelValue.value = url
        }
        img.src = url


      })
    }

    return {
      modelValue,
      dialog,
      dropFile,
      browseFile,
      
    }
    
  },
}
</script>

<style scoped>

.clickable {
  cursor: pointer;
}

</style>