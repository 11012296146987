<template>
  <div class="editor-container">
    <div :class="{ 'editor-error': errorMessages.length }">
      <vue-editor 
        v-model="model"
        :editor-options="editorOptions"
        :custom-modules="customModules"
      ></vue-editor>
    </div>
    <v-input
      :error-messages="errorMessages"
    ></v-input>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { VueEditor, Quill } from "vue2-editor";

window.Quill = Quill

import { ImageDrop } from 'quill-image-drop-module';
import ImageResize from 'quill-image-resize-module';

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);


export default {
  components: {
    VueEditor,
  },
  props: {
    value: String,
    errorMessages: { type: Array, default: () => ([]) },
  },
  setup(props, context) {

    const model = ref(props.value)

    watch(() => props.value, val => {
      model.value = val
    })

    watch(model, val => {
      context.emit('input', val)
    })

    const editorOptions = {
      modules: {
        imageDrop: true,
        imageResize: {},
      }
    }

    const customModules = []


    return {
      model,
      editorOptions,
      customModules,
    }
    
  },
}
</script>

<style scoped>

.editor-container > .editor-error {
  border: 1px solid #ff5252 !important;
}

</style>