<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Staffelprijzen
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Zoek..."
        append-icon="fas fa-search"
        single-line
        hide-details
        @keyup="searchKeyUp()"
      ></v-text-field>
      <v-btn
        class="ml-4"
        depressed
        color="primary"
        @click="newClick"
      >
    <v-icon
      small
      left
    >fas fa-folder-plus</v-icon>
    Nieuw
    </v-btn>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >

      <template v-slot:item.action="{ item }">
        <v-icon
          small
          @click.stop="$refs.confirmDelete.show(item.id)"
        >fas fa-trash</v-icon>
      </template>

    </v-data-table>

    <ConfirmDialog
      ref="confirmDelete"
      title="Verwijderen"
      labelConfirm="Verwijderen"
      labelConfirmColor="error"
      @confirm="deleteClick"
    ></ConfirmDialog>


    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Staffelprijs</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.description"
              :error-messages="errors.description"
              label="Omschrijving"
            ></v-text-field>

            <v-select
              outlined
              class="mb-2"
              :items="tierProductTypes"
              item-text="name"
              item-value="id"
              v-model="model.tier_product_type_id"
              :error-messages="errors.tier_product_type_id"
              label="Producttype"
            ></v-select>

            <v-file-input
              outlined
              class="mb-2"
              prepend-icon
              append-icon="$file"
              v-model="uploadedFile"
              :error-messages="errors.spreadsheet"
              label="Upload"
              @change="uploadFilePicked"
              show-size
            ></v-file-input>

            <number-field
              outlined
              class="mb-2"
              v-model="model.sort_index"
              :error-messages="errors.sort_index"
              label="Sorteerindex"
              integer
            ></number-field>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-btn
            v-if="!isNewRecord"
            color="secondary"
            large
            outlined
            depressed
            @click="pricesDialog = true"
          >Prijstabel</v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Opslaan</v-btn>
        </v-card-actions>


      </v-card>
    </v-dialog>

    <v-dialog
      v-model="pricesDialog"
      max-width="80vw"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Prijstabel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="pricesDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-simple-table>
            <tbody>
              <tr>
                <th>H \ B</th>
                <th
                  v-for="tierProductPricesMaxWidth in tierProductPricesMaxWidths"
                  :key="tierProductPricesMaxWidth"
                  :width="(100 / (tierProductPricesMaxWidths.length + 1)) + '%'"
                >{{ tierProductPricesMaxWidth }} mm</th>
              </tr>
              <tr
                v-for="tierProductPricesMaxHeight in tierProductPricesMaxHeights"
                :key="tierProductPricesMaxHeight"
              >
                <th
                >{{ tierProductPricesMaxHeight }} mm</th>
                <td
                  v-for="tierProductPricesMaxWidth in tierProductPricesMaxWidths"
                  :key="tierProductPricesMaxWidth"
                >{{ formatCurrency(getTierProductPrice(tierProductPricesMaxWidth, tierProductPricesMaxHeight)) }}</td>
              </tr>
            </tbody>
          </v-simple-table>


        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="pricesDialog = false"
          >Sluiten</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref, computed } from '@vue/composition-api'
import { debounce } from 'lodash'
import ConfirmDialog from '@/components/ConfirmDialog'
import NumberField from '@/components/NumberField'
import { formatCurrency, formatPercentage, numberFormat } from '@/utils'

export default {
  components: {
    ConfirmDialog,
    NumberField,
  },
  setup() {
    const api = inject('api')
    const loader = inject('loader')

    const tierProductTypes = api.resource('tier-product-types').collection().all()

    const tierProductCollection = api.resource('tier-products').collection({
      params: {
        expand: 'tier_product_type',
      },
      rules: {
        query: 'query:description',
      }
    })

    const { items, filter, totalCount, options, isSearching } = tierProductCollection

    const headers = [
      { text: 'Omschrijving', value: 'description' },
      { text: 'Producttype', value: 'tier_product_type.name' },
      { text: 'Sorteerindex', value: 'sort_index' },
      { text: '', value: 'action', align: 'center' },
    ]

    const dialog = ref(false)
    const pricesDialog = ref(false)

    const tierProductModel = api.resource('tier-products').model({
      params: {
        expand: 'tier_product_prices,tier_product_prices_max_widths,tier_product_prices_max_heights',
      },
    })
    const { attributes: model, isNewRecord, errors, isBusy } = tierProductModel

    const tierProductPricesMaxWidths = computed(() => tierProductModel.state.attributes.tier_product_prices_max_widths)
    const tierProductPricesMaxHeights = computed(() => tierProductModel.state.attributes.tier_product_prices_max_heights)

    function getTierProductPrice(maxWidth, maxHeight) {
      const tierProductPrice = (tierProductModel.state.attributes.tier_product_prices || []).find(item => item.max_width === maxWidth && item.max_height === maxHeight)
      return tierProductPrice ? tierProductPrice.price : null
    }

    loader.bindRef(isBusy)

    function newClick() {
      tierProductModel.clear()
      dialog.value = true
      uploadedFile.value = null
    }

    function rowClick(item) {
      tierProductModel.find(item.id).then(() => {
        dialog.value = true
        uploadedFile.value = null
      })
    }

    function saveClick() {
      tierProductModel.save().then(() => {
        tierProductCollection.search()
        dialog.value = false
        if (uploadedFile.value) {
          uploadedFile.value = null
          pricesDialog.value = true
        }
      })
    }

    function deleteClick(id) {
      api.resource('tier-products').remove(id).then(() => {
        tierProductCollection.search()
      })
    }

    const uploadedFile = ref()

    function uploadFilePicked(file) {
      tierProductModel.reset(['spreadsheet'])
      if (file) {
        api.utils.readFileAsDataUrl(file).then(result => {
          tierProductModel.setAttribute('spreadsheet', result)
        })
      } else {
        uploadedFile.value = null
      }
    }

    const searchKeyUp = debounce(tierProductCollection.search, 300)

    return {
      api,
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      isSearching,
      newClick,
      rowClick,
      saveClick,
      deleteClick,
      dialog,
      model,
      errors,
      isNewRecord,
      formatCurrency,
      formatPercentage,
      numberFormat,
      tierProductTypes,
      uploadedFile,
      uploadFilePicked,
      pricesDialog,
      tierProductPricesMaxWidths,
      tierProductPricesMaxHeights,
      getTierProductPrice,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

</style>
