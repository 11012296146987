import { nextTick } from '@vue/composition-api'
import goTo from 'vuetify/lib/services/goto'
import moment from 'moment'
import { utils } from '@/api'

export * from './offer'

moment.locale('nl')


export function scrollToTop() {
  window.scrollTo(0, 0)
}

export function scrollToFirstError() {
  nextTick(() => {
    let el = document.querySelector(".v-input.error--text:first-of-type")
    if (el) {
      let dialog = document.getElementsByClassName('v-dialog--active')[0]
      if (dialog) {
        goTo(el, { container: dialog })
      } else {
        goTo(el)
      }
    }
  })
}

export const readFileAsDataUrl = utils.readFileAsDataUrl

export function formatDate(date, format = 'L') {
  return !utils.isEmpty(date) ? (Number.isInteger(date) ? moment.unix(date).format(format) : moment(date).format(format)) : ''
}

export function formatCurrency(amount) {
  return !utils.isEmpty(amount) ? '€ ' + utils.numberFormat(amount, 2, ',', '.') : ''
}

export function formatPercentage(percentage, decimals) {
  if (decimals) {
    return !utils.isEmpty(percentage) ? utils.numberFormat(percentage, decimals, ',', '.') + '%' : ''
  }
  return !utils.isEmpty(percentage) ? utils.trim(utils.rtrim(utils.numberFormat(percentage, 9, ',', '.'), '0'), ',') + '%' : ''
}

export function formatNumber(number, decimals) {
  if (decimals) {
    return !utils.isEmpty(number) ? utils.numberFormat(number, decimals, ',', '.') : ''
  }
  return !utils.isEmpty(number) ? utils.trim(utils.rtrim(utils.numberFormat(number, 9, ',', '.'), '0'), ',') : ''
}

export const numberFormat = utils.numberFormat
export const trim = utils.trim

export function formatText(text) {
  return text ? String(text).replace(/\r?\n/g, '<br>') : ''
}

export function convertItems(items, castValueToInteger = false) {
  const convertedItems = [];
  if (items) {
    Object.keys(items).forEach(key => {
      convertedItems.push({ value: castValueToInteger ? parseInt(key, 10) : key, text: items[key] })
    })
  }
  return convertedItems
}

export function ucfirst(text) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}
