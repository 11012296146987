<template>
  <v-card class="px-8 py-6">
    <v-card-title>{{ model.name }}</v-card-title>
    <v-card-text>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >

              <v-text-field
                outlined
                v-model="model.contact_name"
                :error-messages="errors.contact_name"
                label="Contactpersoon"
              ></v-text-field>

              <v-text-field
                outlined
                v-model="model.company_name"
                :error-messages="errors.company_name"
                label="Bedrijfsnaam"
              ></v-text-field>

              <v-text-field
                outlined
                v-model="model.address"
                :error-messages="errors.address"
                label="Adres (straat + huisnr)"
              ></v-text-field>

            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  v-model="model.postcode"
                  :error-messages="errors.postcode"
                  label="Postcode"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  v-model="model.city"
                  :error-messages="errors.city"
                  label="Woonplaats"
                ></v-text-field>
              </v-col>
            </v-row>


          </v-col>
          <v-col cols="12" sm="6">

            <v-text-field
              outlined
              v-model="model.phone_number"
              :error-messages="errors.phone_number"
              label="Telefoonnummer"
            ></v-text-field>

            <v-text-field
              outlined
              v-model="model.email"
              :error-messages="errors.email"
              label="E-mail"
            ></v-text-field>

            <v-textarea
              outlined
              v-model="model.remarks"
              :error-messages="errors.remarks"
              label="Notitie"
            ></v-textarea>
          </v-col>
        </v-row>
    </v-card-text>

    <v-card-actions class="px-4">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Opslaan</v-btn>

    </v-card-actions>

  </v-card>
</template>

<script>
import { inject } from '@vue/composition-api'

export default {
  setup(props, context) {
    //const api = inject('api')
    const customerModel = inject('customerModel')
    const { attributes: model, errors } = customerModel

    function goBack() {
      context.root.$router.push({
        name: 'customers',
      })
    }

    function saveClick() {
      customerModel.save().then(() => {
        goBack()
      })
    }

    return {
      model,
      errors,
      saveClick,
      goBack,
    }
  },
}
</script>