<template>
  <div>
    <v-data-table
      ref="offerLinesTable"
      :headers="headers"
      :items="items"
      :loading="isSearching"
      class="data-table-offer-lines mb-8"
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
      v-bind="$attrs"
      @click:row="rowClick"
    >

      <template v-slot:header.action="{}">
        <v-switch
          v-show="offer.is_editable"
          v-model="enableSort"
          dense
          class="mt-0 pt-0 data-table-header-switch"
          label="Sorteren"
          hide-details
        ></v-switch>
      </template>

      <template v-slot:item.offer_line_status_id="{ item }">
        <v-chip
          small
          dark
          :color="getOfferLineStatusColor(item.offer_line_status_id, item.is_long_delivery_time)"
        >{{ item.offer_line_status.name }}</v-chip>
      </template>

      <template v-slot:item.quantity="{ item }">
        {{ formatNumber(item.quantity) }} {{ item.unit }}
      </template>

      <template v-slot:item.unit_price="{ item }">
        {{ formatCurrency(item.unit_price) }}
      </template>

      <template v-slot:item.price="{ item }">
        {{ formatCurrency(item.price) }}
      </template>

      <template v-slot:item.action="{ item }">
        <v-btn
          v-show="offer.is_editable"
          icon
          @click.stop="$refs.duplicateRowClickDialog.show(item)"
        ><v-icon small>fas fa-plus</v-icon></v-btn>
        <v-btn
          v-show="offer.is_editable"
          icon
          @click.stop="$refs.deleteRowClickDialog.show(item)"
        ><v-icon small>fas fa-trash-alt</v-icon></v-btn>
      </template>

    </v-data-table>

    <ConfirmDialog
      ref="duplicateRowClickDialog"
      @confirm="duplicateRowClick"
      label-confirm-color="primary"
      label-confirm="Dupliceren"
    ></ConfirmDialog>

    <ConfirmDialog
      ref="deleteRowClickDialog"
      @confirm="deleteRowClick"
      label-confirm-color="error"
      label-confirm="Verwijderen"
    ></ConfirmDialog>

    <v-row>
      <v-col
        cols="12"
        md="3"
      >

        <v-btn
          v-show="offer.is_editable"
          color="primary"
          depressed
          dense
          @click="dialog = true"
        >
          <v-icon small left>fas fa-plus</v-icon>
          Toevoegen
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        offset-md="1"
        md="8"
      
      >
        <v-card
          v-if="offer.prices"
          outlined
        >
          <v-simple-table
            dense
            color="primary"
            class="data-table-price-block pa-5"
          >
            <tbody>
              <tr>
                <td>Subtotaal incl BTW</td>
                <td align="right">{{ formatCurrency(oldOffer.prices.offer_lines_price) }}</td>
                <td>&nbsp;</td>
              </tr>
              <tr v-if="!hasOldNoDiscount">
                <td v-if="hasDiscountTypePercentage">{{ formatPercentage(oldOffer.discount) }} Korting</td>
                <td v-else>Korting</td>
                <td align="right">{{ formatCurrency(-oldOffer.prices.discount_price) }}</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td class="pt-6">Totaal ex. BTW</td>
                <td class="pt-6" align="right">{{ formatCurrency(oldOffer.prices.price_ex) }}</td>
                <td>&nbsp;</td>
              </tr>
              <tr
                v-for="(price_vat, index) in oldOffer.prices.price_vats"
                :key="'vat_'+index"
              >
                <td>{{ formatPercentage(price_vat.percentage) }} BTW</td>
                <td align="right">{{ formatCurrency(price_vat.amount) }}</td>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td class="font-weight-bold">Totaal (incl BTW):</td>
                <td class="font-weight-bold" align="right">{{ formatCurrency(oldOffer.prices.price_in) }}</td>
                <td class="" align="right">
                  <v-btn
                    icon
                    x-small
                    @click="discountDialog = true"
                  ><v-icon x-small>fas fa-plus</v-icon></v-btn>

                </td>
              </tr>
            </tbody>
          </v-simple-table>

        </v-card>

      </v-col>

    </v-row>


    <v-dialog
      v-model="dialog"
      max-width="400"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Nieuw product toevoegen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-list
        >
          <v-list-item
            v-for="(offerLineType, index) in offerLineTypes"
            :key="index"
            link
            class="pl-6"
            @click="newProductClick(offerLineType)"
          >
            <v-list-item-icon>
              <v-icon small>fas fa-arrow-right</v-icon>
            </v-list-item-icon>          
            <v-list-item-content>
              <v-list-item-title v-text="offerLineType.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-divider></v-divider>

      </v-card>
    </v-dialog>

    <v-dialog
      v-model="discountDialog"
      max-width="500"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Korting</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="discountDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text>
          <v-row align="center" justify="center" class="my-8">
            <v-btn-toggle
              v-model="offer.discount_type"
              mandatory
              @change="offer.discount = 0"
            >
              <v-btn :value="null">-</v-btn>
              <v-btn value="percentage">%</v-btn>
              <v-btn value="amount">€</v-btn>
            </v-btn-toggle>
          </v-row>

          <number-field
            outlined
            v-model="offer.discount"
            :prefix="hasDiscountTypeAmount ? '€' : null"
            :suffix="hasDiscountTypePercentage ? '%' : null"
            :disabled="hasNoDiscount"
          ></number-field>



        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            outlined
            depressed
            @click="discountDialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            depressed
            @click="saveDiscountClick()"
          >Opslaan</v-btn>
        </v-card-actions>        

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { inject, ref, watch, onUpdated } from '@vue/composition-api'
import ConfirmDialog from '@/components/ConfirmDialog'
import NumberField from '@/components/NumberField'
import { formatNumber, formatCurrency, formatPercentage, getOfferLineStatusColor, useOffer } from '@/utils'
import Sortable from 'sortablejs'


export default {
  components: {
    ConfirmDialog,
    NumberField,
  },
  setup(props, context) {
    const api = inject('api')
    const offerModel = inject('offerModel')
    const offerLineCollection = inject('offerLineCollection')

    const { attributes: offer, oldAttributes: oldOffer } = offerModel
    const { items, isSearching } = offerLineCollection
    const { hasNoDiscount, hasOldNoDiscount, hasDiscountTypePercentage, hasDiscountTypeAmount } = useOffer(offerModel)

    const offerLineTypes = api.resource('offer-line-types').collection().all()

    const headers = [
      { text: 'Status', value: 'offer_line_status_id', width: '10%' },
      { text: 'Omschrijving', value: 'description', width: '55%' },
      { text: 'Aantal', value: 'quantity', width: '10%' },
      { text: 'Stuksprijs', value: 'unit_price', width: '10%' },
      { text: 'Prijs', value: 'price', width: '10%' },
      { text: '', value: 'action', align: 'right', width: '5%' },
    ]

    const enableSort = ref(false)
    const dialog = ref(false)
    const discountDialog = ref(false)
    const offerLineModel = api.resource('offer-lines').model()

    function rowClick(item) {
      context.root.$router.push({
        name: 'offer-line',
        params: { offer_id: item.offer_id, id: item.id },
      })
    }

    function newProductClick(offerLineType) {
      context.root.$router.push({
        name: 'offer-line-new',
        params: {
          offer_id: offerModel.getAttribute('id'),
          offer_line_type_id: offerLineType.id 
        },
      })

      // offerLineModel.clear()
      // offerLineModel.setAttributes({
      //   offer_id: offerModel.getAttribute('id'),
      //   offer_line_type_id: offerLineType.id,
      //   description: offerLineType.name,
      // })
      // offerLineModel.save().then(() => {
      //   context.root.$router.push({
      //     name: 'offer-line',
      //     params: { id: offerLineModel.getAttribute('id') },
      //   })
      // })
    }

    function deleteRowClick(item) {
      api.resource('offer-lines').delete(item.id).then(() => {
        offerLineCollection.search()
        offerModel.refresh()
      })
    }

    function duplicateRowClick(item) {
      api.resource('offer-lines').post(`${item.id}/duplicate`).then(() => {
        offerLineCollection.search()
        offerModel.refresh()
      })
    }

    const sortableTables = []

    watch(enableSort, value => {
      sortableTables.forEach(sortableTable => {
        sortableTable.$sortable.option('disabled', !value)
      })
    })

    function handleSortableTable(sortableTable) {
      if (sortableTable && !sortableTable.$sortable) {
        let table = sortableTable.$el.getElementsByTagName('tbody')[0]
        sortableTable['$sortable'] = Sortable.create(table, {
          //draggable: '[data-sortable]',
          disabled: !enableSort.value,
          onEnd({ oldIndex, newIndex }) {
            if (oldIndex != newIndex) {
              let oldItem = sortableTable.items[oldIndex]
              let newItem = sortableTable.items[newIndex]
              let newSortIndex = newIndex > oldIndex ? newItem.sort_index + 1 : newItem.sort_index - 1
              offerLineModel.clear()
              offerLineModel.populate({ id: oldItem.id})
              offerLineModel.setAttributes({ sort_index: newSortIndex })
              offerLineModel.save().finally(() => {
                offerLineCollection.search()
              })
            }
          },
        })
        sortableTables.push(sortableTable)
      }
    }

    onUpdated(() => {
      handleSortableTable(context.refs['offerLinesTable'])
    })

    function saveDiscountClick() {
      offerModel.save(['discount_type', 'discount']).then(() => {
        discountDialog.value = false
      })

    }



    return {
      headers,
      items,
      isSearching,
      rowClick,
      newProductClick,
      deleteRowClick,
      duplicateRowClick,
      offer,
      oldOffer,
      dialog,
      discountDialog,
      enableSort,
      offerLineTypes,
      formatNumber,
      formatCurrency,
      formatPercentage,
      getOfferLineStatusColor,
      saveDiscountClick,
      hasNoDiscount,
      hasOldNoDiscount,
      hasDiscountTypePercentage,
      hasDiscountTypeAmount,
    }
  },
}
</script>

<style scoped>

::v-deep .data-table-header-switch .v-label {
  font-size: 12px;
}

::v-deep .data-table-offer-lines table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

::v-deep .data-table-price-block table tbody tr:not(:last-child) td {
  border-bottom: 0 !important;
}

::v-deep .data-table-price-block table tbody tr:hover {
  background: inherit !important;
}

::v-deep .data-table-price-block {
  background-color: #EAEAEA !important;
}

</style>