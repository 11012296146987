<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Bestellingen
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Zoek..."
        append-icon="fas fa-search"
        single-line
        hide-details
        clearable
        clear-icon="fa-times"
        @keyup="searchKeyUp()"
        @click:clear="searchKeyUp()"
      ></v-text-field>
      <v-select
        outlined
        dense
        single-line
        hide-details
        clearable
        clear-icon="fa-times"
        class="ml-4"
        v-model="filter.supplier_id"
        :items="suppliers"
        item-value="id"
        item-text="company_name"
        @change="search()"
        @click:clear="search()"
        placeholder="Leverancier"
      ></v-select>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >

      <template v-slot:item.purchase_date="{ item }">
        {{ formatDate(item.purchase_date) }}
      </template>

      <template v-slot:item.delivery_date="{ item }">
        {{ formatDate(item.delivery_date) }}
      </template>

    </v-data-table>

    <v-dialog
      v-model="offerLinesDialog"
      max-width="900"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Offerteregels</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="offerLinesDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">

          <offer-lines-table
            :id="purchaseOrderId"
          ></offer-lines-table>

        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            outlined
            depressed
            @click="offerLinesDialog = false"
          >Sluiten</v-btn>
        </v-card-actions>


      </v-card>

    </v-dialog>



  </v-card>
  
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import { formatDate } from '@/utils'
import OfferLinesTable from './overview/OfferLinesTable'

export default {
  components: {
    OfferLinesTable,
  },
  setup() {
    const api = inject('api')

    const purchaseOrderCollection = api.resource('purchase-orders').collection({
      params: {
        expand: 'supplier',
        sort: '-id',
      },
      rules: {
        query: 'query:contact_name,company_name,purchase_number,postcode,city,email,phone_number',
      },
      store: true,
    })

    const { items, filter, totalCount, options, search, isSearching } = purchaseOrderCollection

    const suppliers = api.resource('suppliers').collection({
      params: {
        sort: 'company_name',
      }
    }).all()

    const headers = [
      { text: 'Leverancier', value: 'supplier.company_name' },
      { text: 'Inkoopnummer', value: 'purchase_number' },
      { text: 'Besteldatum', value: 'purchase_date' },
      { text: 'Verwachte leverdatum', value: 'delivery_date' },
    ]

    const offerLinesDialog = ref(false)
    const purchaseOrderId = ref()

    function rowClick(item) {
      purchaseOrderId.value = item.id
      offerLinesDialog.value = true
    }

    const searchKeyUp = debounce(purchaseOrderCollection.search, 300)

    return {
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      isSearching,
      rowClick,
      search,
      suppliers,
      formatDate,
      offerLinesDialog,
      purchaseOrderId,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

</style>
