<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Artikelen
      </v-card-title>
      <v-spacer></v-spacer>
      <v-select
        outlined
        dense
        v-model="filter.product_group_id"
        :items="productGroups"
        item-text="name"
        item-value="id"
        single-line
        hide-details
        label="Artikelgroep"
        clearable
        clear-icon="fa-times"
        style="max-width:300px"
        @change="filterChange()"
        class="ml-4"
      ></v-select>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :loading="isSearching"
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
    >

      <template v-slot:item.quantity="{ item }">
        <number-field
          outlined
          dense
          hide-details
          v-model="item.quantity"
        ></number-field>
      </template>

      <template v-slot:item.price="{ item }">
        <number-field
          outlined
          dense
          hide-details
          prefix="€"
          :suffix="item.unit"
          v-model="item.price"
        ></number-field>
      </template>


    </v-data-table>

    <v-btn
      color="primary"
      class="mt-6"
      depressed
      @click="addClick()"
    >Toevoegen</v-btn>


  </v-card>
</template>

<script>
import { inject } from '@vue/composition-api'
import NumberField from '@/components/NumberField'

export default {
  components: {
    NumberField,
  },
  setup(props, context) {

    const api = inject('api')

    const offerModel = inject('offerModel')
    const offerLineTypeModel = inject('offerLineTypeModel')

    const productGroups = api.resource('product-groups').collection().all()

    const productCollection = api.resource('products').collection({
      params: {
        expand: 'default_supplier',
      },
      itemsPerPage: null,
      rules: {
      },
    })

    const { items, filter, options, isSearching } = productCollection

    const headers = [
      { text: 'Product', value: 'name', width: '45%' },
      { text: 'Leverancier', value: 'default_supplier.company_name', width: '20%' },
      { text: 'Aantal', value: 'quantity', width: '15%' },
      { text: 'Stukprijs', value: 'price', width: '20%' },
    ]

    const filterChange = productCollection.search

    function addClick() {

      let offerLineModels = []

      productCollection.forEach(item => {
        if (item.quantity) {
          let offerLineModel = api.resource('offer-lines').model()
          offerLineModel.setAttributes({
            offer_id: offerModel.getAttribute('id'),
            offer_line_type_id: offerLineTypeModel.getAttribute('id'),
            product_id: item.id,
            description: item.name,
            quantity: item.quantity,
            unit_price: item.price,
            unit: item.unit,
          })
          offerLineModels.push(offerLineModel.save())
        }
      })

      Promise.all(offerLineModels).then(() => {
        context.root.$router.push({
          name: 'offer',
          params: { id: offerModel.getAttribute('id') },
        })
      })

    }


    return {
      headers,
      items,
      filter,
      options,
      isSearching,
      productGroups,
      filterChange,
      addClick,
    }

    
  },
}
</script>
