<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Systemen
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-4"
        depressed
        color="primary"
        @click="newClick"
      >
    <v-icon
      small
      left
    >fas fa-folder-plus</v-icon>
    Nieuw
    </v-btn>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >

      <template v-slot:item.width_min="{ item }">
        {{ formatNumber(item.width_min) }} cm
      </template>

      <template v-slot:item.width_max="{ item }">
        {{ formatNumber(item.width_max) }} cm
      </template>

      <template v-slot:item.price="{ item }">
        {{ formatCurrency(item.price) }}
      </template>

      <template v-slot:item.action="{ item }">
        <v-icon
          small
          @click.stop="$refs.confirmDelete.show(item.id)"
        >fas fa-trash</v-icon>
      </template>

    </v-data-table>

    <ConfirmDialog
      ref="confirmDelete"
      title="Verwijderen"
      labelConfirm="Verwijderen"
      labelConfirmColor="error"
      @confirm="deleteClick"
    ></ConfirmDialog>


    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Systeem</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>
            <NumberField
              outlined
              class="mb-2"
              v-model="model.width_min"
              :error-messages="errors.width_min"
              label="Van"
              suffix="cm"
            ></NumberField>

            <NumberField
              outlined
              class="mb-2"
              v-model="model.width_max"
              :error-messages="errors.width_max"
              label="Tot"
              suffix="cm"
            ></NumberField>

            <NumberField
              outlined
              class="mb-2"
              v-model="model.price"
              :error-messages="errors.price"
              label="Prijs"
              prefix="€"
            ></NumberField>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Opslaan</v-btn>
        </v-card-actions>


      </v-card>





    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import ConfirmDialog from '@/components/ConfirmDialog'
import NumberField from '@/components/NumberField'
import { formatNumber, formatCurrency } from '@/utils'

export default {
  components: {
    ConfirmDialog,
    NumberField,
  },
  setup() {
    const api = inject('api')
    const loader = inject('loader')

    const systemCollection = api.resource('systems').collection({
      rules: {
      }
    })

    const { items, filter, totalCount, options, isSearching } = systemCollection

    const headers = [
      { text: 'Van', value: 'width_min' },
      { text: 'Tot', value: 'width_max' },
      { text: 'Prijs', value: 'price' },
      { text: '', value: 'action', align: 'center' },
    ]

    const dialog = ref(false)

    const systemModel = api.resource('systems').model()
    const { attributes: model, errors, isBusy } = systemModel

    loader.bindRef(isBusy)

    function newClick() {
      systemModel.clear()
      dialog.value = true
    }

    function rowClick(item) {
      systemModel.find(item.id).then(() => {
        dialog.value = true
      })
    }

    function saveClick() {
      systemModel.save().then(() => {
        systemCollection.search()
        dialog.value = false
      })
    }

    function deleteClick(id) {
      api.resource('systems').remove(id).then(() => {
        systemCollection.search()
      })
    }

    const searchKeyUp = debounce(systemCollection.search, 300)

    return {
      api,
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      isSearching,
      newClick,
      rowClick,
      saveClick,
      deleteClick,
      dialog,
      model,
      errors,
      formatNumber,
      formatCurrency,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

</style>
