<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Mail templates
      </v-card-title>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-4"
        depressed
        color="primary"
        @click="newClick"
      >
      <v-icon
        small
        left
      >fas fa-folder-plus</v-icon>
      Nieuw
    </v-btn>

    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >
   
    </v-data-table>




    <v-dialog
      v-model="dialog"
      max-width="900"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Mail template</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.description"
              :error-messages="errors.description"
              label="Omschrijving"
            ></v-text-field>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.subject"
              :error-messages="errors.subject"
              label="Onderwerp"
            ></v-text-field>

            <editor-field
              v-model="model.body"
            ></editor-field>

            <number-field
              outlined
              class="mb-2"
              v-model="model.sort_index"
              :error-messages="errors.sort_index"
              label="Sorteerindex"
              integer
            ></number-field>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Opslaan</v-btn>
        </v-card-actions>


      </v-card>





    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import EditorField from '@/components/EditorField'
import NumberField from '@/components/NumberField'

export default {
  components: {
    EditorField,
    NumberField,
  },
  setup() {
    const api = inject('api')
    const loader = inject('loader')

    const mailTemplateCollection = api.resource('mail-templates').collection({
      rules: {
        query: 'query',
      }
    })

    mailTemplateCollection.search()
    const { items, filter, totalCount, options, isSearching } = mailTemplateCollection

    const headers = [
      { text: 'Omschrijving', value: 'description' },
      { text: 'Sorteerindex', value: 'sort_index' },
    ]

    const dialog = ref(false)

    const mailTemplateModel = api.resource('mail-templates').model()
    const { attributes: model, errors, isBusy } = mailTemplateModel

    loader.bindRef(isBusy)

    function rowClick(item) {
      mailTemplateModel.find(item.id).then(() => {
        dialog.value = true
      })
    }

    function saveClick() {
      mailTemplateModel.save().then(() => {
        mailTemplateCollection.search()
        dialog.value = false
      })
    }

    function newClick() {
      mailTemplateModel.clear()
      dialog.value = true
    }


    return {
      headers,
      items,
      filter,
      totalCount,
      options,
      isSearching,
      rowClick,
      saveClick,
      newClick,
      dialog,
      model,
      errors,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

</style>
