<template>
  <div>
    <div class="mx-3 mt-4 mb-8">
      <v-row>
        <v-col cols="12" sm="6" md="3">
          <v-card
            flat
            class="py-6"
            @click="clickOffers()"
          >
            <v-card-text>
              <v-row align="center">
                <v-col
                  class="text-right"
                  cols="4"
                >
                  <v-avatar
                    color="#fde0ce"
                    size="48"
                  ><v-icon
                    color="#de6f2a"
                    dark
                  >fas fa-receipt</v-icon></v-avatar>
                </v-col>
                <v-col
                  cols="8"
                >
                  <div class="text-h5 font-weight-bold">{{ dashboard.offers_todo }}</div>
                  <div class="text-truncate">Openstaande offertes</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card
            flat
            class="py-6"
            @click="clickBilling()"
          >
            <v-card-text>
              <v-row align="center">
                <v-col
                  class="text-right"
                  cols="4"
                >
                  <v-avatar
                    color="#d3f0ff"
                    size="48"
                  ><v-icon
                    color="#0081ba"
                    dark
                  >fas fa-clipboard-list-check</v-icon></v-avatar>
                </v-col>
                <v-col
                  cols="8"
                >
                  <div class="text-h5 font-weight-bold">{{ dashboard.billing_todo }}</div>
                  <div class="text-truncate">Openstaande facturen</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card
            flat
            class="py-6"
            @click="clickPurchasing()"
          >
            <v-card-text>
              <v-row align="center">
                <v-col
                  class="text-right"
                  cols="4"
                >
                  <v-avatar
                    color="#c2e5cc"
                    size="48"
                  ><v-icon
                    color="#00972d"
                    dark
                  >fas fa-shopping-cart</v-icon></v-avatar>
                </v-col>
                <v-col
                  cols="8"
                >
                  <div class="text-h5 font-weight-bold">{{ dashboard.purchasing_todo }}</div>
                  <div class="text-truncate">Producten in te kopen</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-card
            flat
            class="py-6"
            @click="clickCustomers()"
          >
            <v-card-text>
              <v-row align="center">
                <v-col
                  class="text-right"
                  cols="4"
                >
                  <v-avatar
                    color="#e2c0e0"
                    size="48"
                  ><v-icon
                    color="#830d7f"
                    dark
                  >fas fa-users</v-icon></v-avatar>
                </v-col>
                <v-col
                  cols="8"
                >
                  <div class="text-h5 font-weight-bold">{{ dashboard.customers }}</div>
                  <div class="text-truncate">Geregistreerde klanten</div>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <v-card
      flat
      class="ma-3 pa-6"
    >
      <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
        <v-card-title>
          Na te bellen klanten
        </v-card-title>
        <v-spacer></v-spacer>
        <v-text-field
          outlined
          dense
          v-model="filter.query"
          placeholder="Zoek..."
          append-icon="fas fa-search"
          single-line
          hide-details
          clearable
          clear-icon="fa-times"
          @keyup="searchKeyUp()"
        ></v-text-field>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="options"
        :server-items-length="totalCount"
        :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
        :loading="isSearching"
        @click:row="rowClick"
      >

        <template v-slot:item.offer_status_id="{ item }">
          <v-icon :color="getOfferStatusColor(item.offer_status_id)" x-small>fas fa-circle</v-icon>
          <span class="ml-3" :style="{ color: getOfferStatusColor(item.offer_status_id) }">{{ item.offer_status.name }}</span>
        </template>

        <template v-slot:item.offer_date="{ item }">
          {{ formatDate(item.offer_date) }}
        </template>

        <template v-slot:item.reminder_date="{ item }">
          {{ formatDate(item.reminder_date) }}
        </template>

      </v-data-table>


    </v-card>
  </div>
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import { formatDate } from '@/utils'
import { getOfferStatusColor, OFFER_STATUS_OFFERED } from '@/utils/offer'

export default {
  components: {
  },
  setup(props, context) {
    const api = inject('api')

    const offerCollection = api.resource('offers').collection({
      params: {
        expand: 'customer,offer_status',
        filter: {
          not: { reminder_date: 'NULL' },
          offer_status_id: { eq: OFFER_STATUS_OFFERED },
        },
        sort: 'reminder_date',
      },
      rules: {
        query: 'query:offer_number,contact_name,company_name,address,postcode,city,email,phone_number',
      },
      
      store: true,
    })

    const { items, filter, totalCount, options, isSearching } = offerCollection

    const dashboard = ref({
      offers_todo: null,
      billing_todo: null,
      purchasing_todo: null,
      customers: null,
    })

    api.resource('users').get('dashboard').then(response => {
      dashboard.value = response.data
    })

    const headers = [
      { text: 'Offertenummer', value: 'offer_number' },
      { text: 'Offertedatum', value: 'offer_date' },
      { text: 'Nabellen vanaf', value: 'reminder_date' },
      { text: 'Klantnaam', value: 'customer.name' },
      { text: 'Woonplaats', value: 'customer.city' },
      { text: 'Status', value: 'offer_status_id' },
      { text: '', value: 'action', align: 'center' },
    ]

    // const buttons

    function rowClick(item) {
      context.root.$router.push({
        name: 'offer',
        params: { id: item.id }
      })
    }

    const searchKeyUp = debounce(offerCollection.search, 300)

    function clickOffers() {
      context.root.$router.push({
        name: 'offers',
      })
    }

    function clickBilling() {
      context.root.$router.push({
        name: 'billing',
      })
    }

    function clickPurchasing() {
      context.root.$router.push({
        name: 'buy',
      })
    }

    function clickCustomers() {
      context.root.$router.push({
        name: 'customers',
      })
    }

    return {
      dashboard,
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      isSearching,
      formatDate,
      rowClick,
      getOfferStatusColor,
      clickOffers,
      clickBilling,
      clickPurchasing,
      clickCustomers,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

</style>
