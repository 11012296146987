<template>
  <v-menu
    v-model="dialog"
    :close-on-content-click="false"
    :nudge-right="40"
    :disabled="disabled"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >

    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="formatDate(internalValue)"
        :label="label"
        :error-messages="errorMessages"
        clearable
        clear-icon="fa-times"
        readonly
        :outlined="outlined"
        :disabled="disabled"
        autocomplete="off"
        v-bind="{ ...$attrs, ...attrs }"
        v-on="{ ...$listeners, ...on }"
        @click:clear="internalValue = null"
      ></v-text-field>
    </template>

    <v-date-picker
      v-model="internalValue"
      locale="nl-nl"
      @input="dialog = false"
      show-week
      first-day-of-week="1"
      v-bind="{ ...options }"
    ></v-date-picker>

  </v-menu>
</template>

<script>
//      :allowed-dates="allowedProjectDates"

import { ref, watch } from '@vue/composition-api'
import { formatDate } from '@/utils'


export default {
  props: {
    value: String,
    label: String,
    disabled: { type: Boolean, default: false },
    errorMessages: Array,
    outlined: { type: Boolean, default: false },
    options: Object,
  },
  setup(props, context) {
    const dialog = ref(false)
    const internalValue = ref(props.value)

    watch(() => props.value, (value) => {
      internalValue.value = value
    })

    watch(() => internalValue.value, (value) => {
      context.emit('input', value)
      context.emit('change')
    })

    return {
      dialog,
      internalValue,
      formatDate,
    }

  }
}
</script>