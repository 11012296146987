<template>
  <v-card
    flat
    class="px-8 py-6"
  >
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="isSearching"
      disable-pagination
      disable-sort
      disable-filtering
      hide-default-footer
      @click:row="rowClick"
    >

      <template v-slot:item.offer_status_id="{ item }">
        <v-icon :color="getOfferStatusColor(item.offer_status_id)" x-small>fas fa-circle</v-icon>
        <span class="ml-3" :style="{ color: getOfferStatusColor(item.offer_status_id) }">{{ item.offer_status.name }}</span>
      </template>

      <template v-slot:item.offer_date="{ item }">
        {{ formatDate(item.offer_date) }}
      </template>

    </v-data-table>

  </v-card>
  
</template>

<script>
import { inject } from '@vue/composition-api'
import { formatDate, getOfferStatusColor } from '@/utils'

export default {
  components: {
  },
  setup(props, context) {
    const api = inject('api')
    const customerModel = inject('customerModel')

    const offerCollection = api.resource('offers').collection({
      params: {
        expand: 'customer,offer_status',
      },
      rules: {
        query: 'query:offer_number,contact_name,company_name,address,postcode,city,email,phone_number',
      },
    })

    const { items, isSearching } = offerCollection

    customerModel.onReady(customer => {
      offerCollection.setFilter('customer_id', customer.id)
      offerCollection.search()
    })

    const headers = [
      { text: 'Offertenummer', value: 'offer_number' },
      { text: 'Datum', value: 'offer_date' },
      { text: 'Klantnaam', value: 'customer.name' },
      { text: 'Woonplaats', value: 'customer.city' },
      { text: 'Status', value: 'offer_status_id' },
      { text: '', value: 'action', align: 'center' },
    ]

    function rowClick(item) {
      context.root.$router.push({
        name: 'offer',
        params: { id: item.id }
      })
    }

    return {
      headers,
      items,
      isSearching,
      formatDate,
      rowClick,
      getOfferStatusColor,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

</style>
