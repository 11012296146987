<template>
  <v-autocomplete
    v-bind="$attrs"
    :items="items"
    :loading="isSearching"
    :search-input.sync="filter.query"
    clearable
    clear-icon="fa-times"
    label="Zoek bestaande klant..."
    hide-details
    no-filter
    return-object
    @keyup="searchKeyUp()"
    @change="searchChanged"
    @click:clear="searchCleared"
  >

    <template v-slot:no-data>
      <v-list-item>
        <v-list-item-title>
          Geen klanten gevonden
        </v-list-item-title>
      </v-list-item>
    </template>

    <template v-slot:selection="{ attr, on, item }">
      <span v-text="formatSelectedAddress(item)"></span>
    </template>            

    <template v-slot:item="{ item }">
      <v-list-item-content>
        <v-list-item-title v-text="formatAddressTitle(item)"></v-list-item-title>
        <v-list-item-subtitle v-text="formatAddressSubtitle(item)"></v-list-item-subtitle>
      </v-list-item-content>
    </template>

  </v-autocomplete>
  
</template>


<script>
import { inject } from '@vue/composition-api'
import { debounce } from 'lodash'

export default {
  setup(props, context) {
    const api = inject('api')

    const customerCollection = api.resource('customers').collection({
      rules: {
        query: 'query:contact_name,company_name,address,postcode,city,email,phone_number',
      },
    })

    const { items, filter, isSearching } = customerCollection

    const searchKeyUp = debounce(customerCollection.search, 300)

    function searchChanged(item) {
      context.emit('change', item)
    }

    function formatSelectedAddress(item) {
      return (item.name || '') + ', ' + (item.address || '') + ', ' + (item.city || '')
    }

    function formatAddressTitle(item) {
      return (item.name || '') + (item.contact_name ? ' (' + item.contact_name + ')' : '')
    }

    function formatAddressSubtitle(item) {
      return (item.address || '') + ', ' + (item.postcode || '') + ', ' + (item.city || '')
    }

    function searchCleared() {
      context.emit('change')
    }

    return {
      items,
      filter,
      isSearching,
      searchKeyUp,
      searchChanged,
      searchCleared,

      formatSelectedAddress,
      formatAddressTitle,
      formatAddressSubtitle,
    }

    
  },
}
</script>