<template>
  <v-form 
    @submit.prevent>
    <v-row
      class="mt-0"
    >
      <v-col
        cols="12"
        md="8"
        lg="9"
      >
        <v-card
          flat
          class="mx-3 px-6 pb-16 mb-6"
        >
       
          <v-card-title class="primary--text px-0">Algemeen</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >
            <v-col
              cols="6"
              lg="4"
            >
              <images-field
                v-model="offerLineProduct.photo_url"
                label="Foto"
                :disabled="!isEditable"
              ></images-field>
            </v-col>
          </v-row>

          <v-row>

            <v-col
              cols="12"
              md="8"
              lg="6"

            >
              <button-field
                v-model="offerLineProduct.curtain_type"
                :items="convertItems(offerLineType.curtain_types)"
                label="Gordijntype"
                @change="updateField('curtain_type')"
                :disabled="!isEditable"
              ></button-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-text-field
                v-model="offerLine.quantity"
                outlined
                label="Aantal"
                class="centered-input"
                readonly
                @change="updateField('quantity')"
                :disabled="!isEditable"
              >
                <template v-slot:prepend-inner>
                  <v-btn
                    small
                    icon
                    @click="offerLine.quantity > 0 ? offerLine.quantity-- : offerLine.quantity"
                    :disabled="!isEditable"
                  ><v-icon small>fas fa-minus</v-icon></v-btn>
                </template>
              
                <template v-slot:append>
                  <v-btn
                    small
                    icon
                    @click="offerLine.quantity++"
                    :disabled="!isEditable"
                  ><v-icon small>fas fa-plus</v-icon></v-btn>
                </template>
              
              </v-text-field>
            </v-col>

          </v-row>

          <v-row
          >
            <v-col
              cols="6"
            >
              <v-text-field
                outlined
                v-model="offerLineProduct.window_description"
                :error-messages="offerLineProductErrors.window_description"
                label="Raamomschrijving"
                @change="updateField('window_description')"
                :disabled="!isEditable"
              ></v-text-field>
            </v-col>

            <v-col
              cols="6"
            >
              <v-text-field
                outlined
                v-model="offerLineProduct.product_features"
                :error-messages="offerLineProductErrors.product_features"
                label="Bijzonderheden"
                @change="updateField('product_features')"
                :disabled="!isEditable"
              ></v-text-field>
            </v-col>



          </v-row>


          <v-card-title class="primary--text px-0">Stofsoort</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                outlined
                v-model="offerLineProduct.fabric_name"
                :error-messages="offerLineProductErrors.fabric_name"
                label="Stofcode en kleurcode"
                @change="updateField('fabric_name')"
                :disabled="!isEditable"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                outlined
                clearable
                v-model="offerLine.supplier_id"
                :error-messages="offerLineErrors.supplier_id"
                :items="suppliers"
                item-value="id"
                item-text="company_name"
                label="Leverancier"
                @change="updateField('supplier_id')"
                :disabled="!isEditable"
              ></v-autocomplete>
            </v-col>
          </v-row>


          <v-card-title class="primary--text px-0">Maatvoering</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_width"
                :error-messages="offerLineProductErrors.dimensions_width"
                integer
                label="Breedte"
                suffix="mm"
                @change="updateField('dimensions_width')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_clear_width"
                :error-messages="offerLineProductErrors.clear_width"
                integer
                label="Klare breedte"
                suffix="mm"
                @change="updateField('dimensions_clear_width')"
                :disabled="!isEditable"
                :background-color="offerLineProduct.dimensions_clear_width_measured ? 'green' : 'red'"
              >
                <template v-slot:prepend>
                  <simple-checkbox v-model="offerLineProduct.dimensions_clear_width_measured" :true-value="1" :false-value="0" ></simple-checkbox>
                </template>
              </number-field>
            </v-col>

            <v-col
              cols="6"
              sm="3"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_min_height"
                :error-messages="offerLineProductErrors.dimensions_min_height"
                integer
                persistent-placeholder
                label="Minimale hoogte"
                suffix="mm"
                @change="updateField('dimensions_min_height')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="6"
              sm="3"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_max_height"
                :error-messages="offerLineProductErrors.dimensions_max_height"
                integer
                persistent-placeholder
                label="Maximale hoogte"
                suffix="mm"
                @change="updateField('dimensions_max_height')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_clear_height"
                :error-messages="offerLineProductErrors.clear_height"
                integer
                label="Klare hoogte"
                suffix="mm"
                @change="updateField('dimensions_clear_height')"
                :disabled="!isEditable"
                :background-color="offerLineProduct.dimensions_clear_height_measured ? 'green' : 'red'"
              >
                <template v-slot:prepend>
                  <simple-checkbox v-model="offerLineProduct.dimensions_clear_height_measured" :true-value="1" :false-value="0" ></simple-checkbox>
                </template>
              </number-field>
            </v-col>

          </v-row>



          <v-card-title class="primary--text px-0">Systeem</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >
            <v-col
              cols="12"
              sm="6"
            >
              <v-combobox
                outlined
                clearable
                v-model="offerLineProduct.system_control"
                :error-messages="offerLineProductErrors.system_control"
                :items="convertItems(offerLineType.system_controls)"
                label="Bediening"
                :return-object="false"
                @change="updateField('system_control')"
                :disabled="!isEditable"
              ></v-combobox>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-combobox
                outlined
                clearable
                v-model="offerLineProduct.system_type"
                :error-messages="offerLineProductErrors.system_type"
                :items="convertItems(offerLineType.system_types)"
                label="Model"
                :return-object="false"
                @change="updateField('system_type')"
                :disabled="!isEditable"
              ></v-combobox>
            </v-col>


            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.system_chain_length"
                :error-messages="offerLineErrors.system_chain_length"
                integer
                label="Koordlengte"
                suffix="mm"
                @change="updateField('system_chain_length')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                outlined
                clearable
                v-model="offerLineProduct.system_id"
                :error-messages="offerLineProductErrors.system_id"
                :items="tierProducts"
                item-value="id"
                item-text="description"
                label="Systeem"
                @change="updateField('system_id')"
                :disabled="!isEditable"
              ></v-select>
            </v-col>
            
            <v-col
              cols="12"
              sm="6"
            >
              <v-combobox
                outlined
                clearable
                v-model="offerLineProduct.system_color"
                :error-messages="offerLineProductErrors.system_color"
                :items="convertItems(offerLineType.system_colors)"
                label="Kleurprofiel"
                :return-object="false"
                @change="updateField('system_color')"
                :disabled="!isEditable"
              ></v-combobox>
            </v-col>


          </v-row>

          <v-card-title class="primary--text px-0">Montage</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >
            <v-col 
              cols="6"
              sm="3"
            >
              <v-combobox
                outlined
                clearable
                v-model="offerLineProduct.mounting_type"
                :error-messages="offerLineProductErrors.mounting_type"
                :items="convertItems(offerLineType.mounting_types)"
                label="Montage"
                :return-object="false"
                @change="updateField('mounting_type')"
                :disabled="!isEditable"
              ></v-combobox>
            </v-col>

            <v-col 
              cols="6"
              sm="3"
            >
              <v-select
                outlined
                clearable
                v-model="offerLineProduct.mounting_material"
                :error-messages="offerLineProductErrors.mounting_material"
                :items="convertItems(offerLineType.mounting_materials)"
                label="Materiaal"
                @change="updateField('mounting_material')"
                :disabled="!isEditable"
              ></v-select>
            </v-col>


            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.mounting_ceiling_height"
                :error-messages="offerLineProductErrors.mounting_ceiling_height"
                integer
                label="Montagehoogte"
                suffix="mm"
                @change="updateField('mounting_ceiling_height')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

          </v-row>


          <v-card-title class="primary--text px-0">Opties</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >
            <v-col
              cols="12"
              sm="6"
            >
              <button-field
                v-model="offerLineProduct.options_special_assembly"
                :items="[{ value: 1, text: 'JA' }, { value: 0, text: 'NEE' }]"
                label="Speciale montage / gereedschap"
                @change="updateField('options_special_assembly')"
                :disabled="!isEditable"
              ></button-field>
            </v-col>

          </v-row>
          <v-row>

            <v-col
              cols="12"
              lg="6"
            >
              <v-text-field
                outlined
                v-model="offerLineProduct.options_description"
                :error-messages="offerLineProductErrors.options_description"
                label="Optieomschrijving"
                @change="updateField('options_description')"
                :disabled="!isEditable"
              ></v-text-field>
            </v-col>

          </v-row>
          <v-row>

            <v-col
              cols="12"
              lg="6"
            >
              <v-textarea
                outlined
                v-model="offerLineProduct.options_remarks"
                :error-messages="offerLineProductErrors.options_remarks"
                label="Overige opmerkingen"
                @change="updateField('options_remarks')"
                :disabled="!isActive"
              ></v-textarea>
            </v-col>

          </v-row>

          <v-card-title class="primary--text px-0">Overzicht</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
            >

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.additional_price"
                :error-messages="offerLineProductErrors.additional_price"
                label="Meerprijs"
                prefix="€"
                decimals="2"
                @change="updateField('additional_price')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLine.unit_price"
                :error-messages="offerLineErrors.unit_price"
                decimals="2"
                label="Prijs"
                prefix="€"
                @change="updateField('unit_price')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>
          </v-row>
          <v-row>

            <v-col
              cols="12"
              sm="6"
            >
              <date-picker
                outlined
                v-model="offerLine.delivery_date"
                :error-messages="offerLineErrors.delivery_date"
                label="Leverweek"
                @change="updateField('delivery_date')"
                :suffix="'week ' + formatDate(offerLine.delivery_date, 'W')"
                :disabled="!isActive"
              ></date-picker>

            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-checkbox
                v-model="offerLine.is_long_delivery_time"
                :error-messages="offerLineErrors.is_long_delivery_time"
                label="Lange levertijd"
                :disabled="!isActive"
              ></v-checkbox>

            </v-col>

          </v-row>


        </v-card>

      </v-col>
      <v-col
        cols="12"
        md="4"
        lg="3"
        class="pl-6 pl-md-3"
      >

        <v-banner
          sticky
          class="sticky-card"
        >

        <v-card
          flat
          class="pb-6"
        >
          <v-toolbar
            dark
            class="primary"
            dense
            elevation="0"
          >
            <v-toolbar-title>{{ offerLineProduct.curtain_type || offerLineType.name }}</v-toolbar-title>
          </v-toolbar>

          <dl class="row my-6 px-6 def-list">

            <dt class="col-6">Gordijntype:</dt>
            <dd class="col-6">{{ offerLineProduct.curtain_type }}</dd>
            <dt class="col-6">Leverancier:</dt>
            <dd class="col-6"><template v-if="supplier">{{ supplier.company_name }}</template></dd>
            <dt class="col-6">Systeemkeuze:</dt>
            <dd class="col-6"><template v-if="tierProduct">{{ tierProduct.description }}</template></dd>
            <dt class="col-6 pt-3 primary--text">Klare breedte:</dt>
            <dd class="col-6 pt-3 primary--text"><template v-if="offerLineProduct.dimensions_clear_width">
                <span :style="{ color: offerLineProduct.dimensions_clear_width_measured ? 'green' : 'red' }">{{ offerLineProduct.dimensions_clear_width }} mm</span>
              </template></dd>
            <dt class="col-6 pb-3 primary--text">Klare hoogte:</dt>
            <dd class="col-6 pb-3 primary--text"><template v-if="offerLineProduct.dimensions_clear_height">
                <span :style="{ color: offerLineProduct.dimensions_clear_height_measured ? 'green' : 'red' }">{{ offerLineProduct.dimensions_clear_height }} mm</span>
              </template></dd>
            <dt class="col-6">Montagehoogte:</dt>
            <dd class="col-6"><template v-if="offerLineProduct.mounting_ceiling_height">{{ offerLineProduct.mounting_ceiling_height }} mm</template></dd>
            <dt class="col-6">Koordlengte:</dt>
            <dd class="col-6"><template v-if="offerLineProduct.system_chain_length">{{ offerLineProduct.system_chain_length }} mm</template></dd>
            <dt class="col-6">Profielkleur:</dt>
            <dd class="col-6">{{ offerLineProduct.system_color }}</dd>

            <dt class="col-6 pt-3">Bijzonderheden:</dt>
            <dd class="col-6 pt-3 error--text">{{ offerLineProduct.product_features }}</dd>

            <dt class="col-6 pt-3">Overige opmerkingen:</dt>
            <dd class="col-6 pt-3">{{ offerLineProduct.options_remarks }}</dd>

            <dt class="col-6 py-3 primary--text">Prijs:</dt>
            <dd class="col-6 py-3 primary--text">{{ formatCurrency(price) }}</dd>

          </dl>

        </v-card>

        <v-btn
          block
          depressed
          color="primary"
          class="mt-6"
          large
          @click="saveClick"
          v-if="isActive"
        >Opslaan</v-btn>

        <v-btn
          block
          outlined
          color="primary"
          class="mt-6 white"
          large
          @click="backClick"
        >Terug</v-btn>

        </v-banner>

      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { inject, computed } from '@vue/composition-api'
import NumberField from '@/components/NumberField'
import ButtonField from '@/components/ButtonField'
import DatePicker from '@/components/DatePicker'
import ImagesField from '@/components/ImagesField.vue'
import SimpleCheckbox from '@/components/SimpleCheckbox'
import { convertItems, useOffer, useOfferLine, formatCurrency, formatDate } from '@/utils'


export default {
  components: {
    NumberField,
    ButtonField,
    DatePicker,
    ImagesField,
    SimpleCheckbox,
  },
  setup(props, context) {

    const api = inject('api')

    const offerModel = inject('offerModel')
    const offerLineTypeModel = inject('offerLineTypeModel')
    const offerLineModel = inject('offerLineModel')
    const offerLineProductModel = inject('offerLineProductModel')

    const suppliers = inject('suppliers')

    const { attributes: offerLineType } = offerLineTypeModel
    const { attributes: offerLine, errors: offerLineErrors, oldAttributes: oldOfferLine } = offerLineModel
    const { attributes: offerLineProduct, errors: offerLineProductErrors, oldAttributes: oldOfferLineProduct } = offerLineProductModel
    const { isEditable, isActive } = useOffer(offerModel)
    const { price, isMountingCoveMoldingInTheDay } = useOfferLine(offerLineModel, offerLineProductModel)

    const supplier = computed(() => suppliers.value ? suppliers.value.find(item => item.id == offerLineModel.getAttribute('supplier_id')) : {})

    // systems
    const tierProducts = api.resource('tier-products').collection().all({
      filter: { tier_product_type_id: { eq: 3 }, },
      sort: 'sort_index',
    })

    const tierProduct = computed(() => tierProducts.value ? tierProducts.value.find(item => item.id == offerLineProductModel.getAttribute('system_id')) : {})

    function updateField(field) {
      console.log('update', field, offerLineProductModel.getAttribute(field))

      if (['system_id', 'dimensions_clear_width', 'dimensions_clear_height'].includes(field)) {
        let systemId = offerLineProductModel.getAttribute('system_id')
        let dimensionsClearWidth = Number(offerLineProductModel.getAttribute('dimensions_clear_width', 0))
        let dimensionsClearHeight = Number(offerLineProductModel.getAttribute('dimensions_clear_height', 0))

        if (systemId && dimensionsClearWidth && dimensionsClearHeight) {
          api.resource('tier-products').get(`${systemId}/price/${dimensionsClearWidth}/${dimensionsClearHeight}`).then(response => {
            offerLineProductModel.setAttribute('system_price', response.data)
            updateField('system_price')
          })
        } else {
            offerLineProductModel.setAttribute('system_price', null)
            updateField('system_price')
        }
      }

      // unit price
      if (['system_price', 'additional_price'].includes(field)) {
        let systemPrice = Number(offerLineProductModel.getAttribute('system_price', 0))
        let additionalPrice = Number(offerLineProductModel.getAttribute('additional_price', 0))
        let unitPrice = systemPrice
        offerLineModel.setAttribute('unit_price', unitPrice + additionalPrice)
        updateField('unit_price')
      }

      // description
      if (['window_description', 'product_features', 'curtain_type', 'fabric_name', 'system_control', 'system_color'].includes(field)) {
        // ruimte/product/bijzonderheden/kleur/type bediening/kleurprofiel
        let description = (offerLineProductModel.getAttribute('window_description') ? offerLineProductModel.getAttribute('window_description') + ', ' : '')
            + (offerLineProductModel.getAttribute('curtain_type') ? offerLineProductModel.getAttribute('curtain_type').toLowerCase() : '')
            + (offerLineProductModel.getAttribute('product_features') ? ', ' + offerLineProductModel.getAttribute('product_features') : '')
            + (offerLineProductModel.getAttribute('fabric_name') ? ', ' + offerLineProductModel.getAttribute('fabric_name') : '')
            + (offerLineProductModel.getAttribute('system_control') ? ', ' + offerLineProductModel.getAttribute('system_control').toLowerCase() : '')
            + (offerLineProductModel.getAttribute('system_color') ? ', kleurprofiel ' + offerLineProductModel.getAttribute('system_color').toLowerCase() : '')
        
        offerLineModel.setAttribute('description', description)
        updateField('description')
      }
    }

    function saveClick() {
      offerLineModel.save().then(({ data: offerLine }) => {
        offerLineProductModel.populate({ offer_line_id: offerLine.id })
        offerLineProductModel.save().then(() => {
          backClick()
        })
      })
    }

    function backClick() {
      context.root.$router.push({
        name: 'offer',
        params: { id: offerLineModel.getAttribute('offer_id') },
      })
    }

    return {
      isEditable,
      isActive,
      offerLineType,
      offerLine,
      offerLineErrors,
      oldOfferLine,
      offerLineProduct,
      offerLineProductErrors,
      oldOfferLineProduct,
      convertItems,
      formatCurrency,
      formatDate,
      suppliers,
      supplier,
      saveClick,
      backClick,
      price,
      tierProducts,
      tierProduct,
      isMountingCoveMoldingInTheDay,
      updateField,
    }
  },
}
</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}

::v-deep .sticky-card {
  top: 140px !important;
}

::v-deep .sticky-card > .v-banner__wrapper {
  padding: 0 !important;
}

dl.def-list dt {
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}

dl.def-list dd {
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}


</style>