<template>
  <v-card
    v-if="offer.versions"
    flat
    class="mt-5"
  >
    <v-toolbar
      dark
      class="primary"
      dense
      elevation="0"
    >
      <v-toolbar-title
      >Historie</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        icon
        :class="{ active: expanded }"
        @click="expanded = !expanded"
      >
        <v-icon small>fas fa-chevron-down</v-icon>
      </v-btn>
    </v-toolbar>
    <v-expand-transition>
      <v-list
        v-show="expanded"
      >
        <v-list-item
          v-for="(version, index) in offer.versions"
          :key="index"
          :to="{ name: 'offer', params: { id: version.id }}"
        >
          <v-list-item-content>
            <v-list-item-title v-text="version.offer_number"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { inject, ref, watch } from '@vue/composition-api'

export default {
  setup() {
    const offerModel = inject('offerModel')
    const { attributes: offer } = offerModel

    const expanded = ref(false)

    watch(offer, offer => {
      if (offer.versions && offer.versions.length > 1) {
        expanded.value = true
      }
    }, { immediate: true })

    return {
      expanded,
      offer,
    }
  },
}
</script>

<style scoped>

.v-btn.active .v-icon {
  transform: rotate(-180deg);
}

</style>