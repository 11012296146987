var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',_vm._b({attrs:{"headers":_vm.headers,"items":_vm.items,"loading":_vm.isSearching,"disable-pagination":"","disable-sort":"","disable-filtering":"","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.offer_line_status_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","dark":"","color":_vm.getOfferLineStatusColor(item.offer_line_status_id)}},[_vm._v(_vm._s(item.offer_line_status.name))])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.quantity))+" "+_vm._s(item.unit)+" ")]}},{key:"item.unit_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.unit_price))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.price))+" ")]}}])},'v-data-table',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }