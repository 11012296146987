<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Offertes
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Zoek..."
        append-icon="fas fa-search"
        single-line
        hide-details
        clearable
        clear-icon="fa-times"
        @click:clear="searchKeyUp()"
        @keyup="searchKeyUp()"
      ></v-text-field>
      <v-select
        outlined
        dense
        v-model="filter.offer_status_id"
        :items="offerStatuses"
        item-text="name"
        item-value="id"
        single-line
        hide-details
        label="Status"
        clearable
        clear-icon="fa-times"
        style="max-width:300px"
        @change="filterChange()"
        class="ml-4"
      ></v-select>
      <v-btn
        class="ml-4"
        depressed
        color="primary"
        @click="newClick"
      >
        <v-icon
          small
          left
        >fas fa-folder-plus</v-icon>
        Nieuw
      </v-btn>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >

      <template v-slot:item.offer_status_id="{ item }">
        <v-icon :color="getOfferStatusColor(item.offer_status_id)" x-small>fas fa-circle</v-icon>
        <span class="ml-3" :style="{ color: getOfferStatusColor(item.offer_status_id) }">{{ item.offer_status.name }}</span>
      </template>

      <template v-slot:item.offer_date="{ item }">
        {{ formatDate(item.offer_date) }}
      </template>

    </v-data-table>

    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Nieuwe offerte</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">

          <CustomerSearch
            @change="customerSearchChanged"
            flat
            outlined
          ></CustomerSearch>

          <v-form @submit.prevent
            class="mt-6"
          >
            
            <legend class="ml-3 mb-3">Klantgegevens</legend>

            <v-text-field
              outlined
              v-model="model.contact_name"
              :error-messages="errors.contact_name"
              label="Klantnaam"
            ></v-text-field>

            <v-text-field
              outlined
              v-model="model.company_name"
              :error-messages="errors.company_name"
              label="Bedrijfsnaam"
            ></v-text-field>

            <v-text-field
              outlined
              v-model="model.address"
              :error-messages="errors.address"
              label="Adres (straat + huisnr)"
            ></v-text-field>

            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  v-model="model.postcode"
                  :error-messages="errors.postcode"
                  label="Postcode"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  v-model="model.city"
                  :error-messages="errors.city"
                  label="Woonplaats"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              outlined
              v-model="model.phone_number"
              :error-messages="errors.phone_number"
              label="Telefoonnummer"
            ></v-text-field>

            <v-text-field
              outlined
              v-model="model.email"
              :error-messages="errors.email"
              label="E-mail"
            ></v-text-field>

            <v-textarea
              outlined
              v-model="model.remarks"
              :error-messages="errors.remarks"
              label="Notitie"
            ></v-textarea>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Aanmaken</v-btn>
        </v-card-actions>


      </v-card>





    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import { scrollToFirstError, formatDate } from '@/utils'
import CustomerSearch from '@/components/CustomerSearch'
import { getOfferStatusColor } from '@/utils/offer'

export default {
  components: {
    CustomerSearch,
  },
  setup(props, context) {
    const api = inject('api')
    const loader = inject('loader')

    const offerStatuses = api.resource('offer-statuses').collection({ params: { sort: 'name' }}).all()

    const offerCollection = api.resource('offers').collection({
      params: {
        expand: 'customer,offer_status',
      },
      rules: {
        query: 'query:company_name,contact_name,address',
      },
      store: true,
      debug: true,
    })

    const { items, filter, totalCount, options, isSearching } = offerCollection

    const headers = [
      { text: 'Offertenummer', value: 'offer_number' },
      { text: 'Datum', value: 'offer_date' },
      { text: 'Bedrijfsnaam', value: 'customer.company_name' },
      { text: 'Contactpersoon', value: 'customer.contact_name' },
      { text: 'Woonplaats', value: 'customer.city' },
      { text: 'Status', value: 'offer_status_id' },
      { text: '', value: 'action', align: 'center' },
    ]

    const dialog = ref(false)


    const customerModel = api.resource('customers').model({ debug: true })
    const { attributes: model, errors, isBusy } = customerModel

    const offerModel = api.resource('offers').model()

    loader.bindRef(isBusy)

    function newClick() {
      customerModel.clear()
      dialog.value = true
    }

    function rowClick(item) {
      context.root.$router.push({
        name: 'offer',
        params: { id: item.id }
      })
    }

    function saveClick() {
      customerModel.save().then(() => {
        offerModel.clear()
        offerModel.setAttribute('customer_id', customerModel.getAttribute('id'))
        offerModel.save().then(response => {
          dialog.value = false
          context.root.$router.push({
            name: 'offer',
            params: { id: response.data.id }
          })
        })
      }).catch(() => {
        scrollToFirstError()
      })
    }

    function customerSearchChanged(item) {
      if (item) {
        customerModel.find(item.id)
      } else {
        customerModel.clear()
      }
    }

    const filterChange = offerCollection.search
    const searchKeyUp = debounce(offerCollection._search, 300)

    const selected = ref([])

    return {
      selected,
      headers,
      items,
      filter,
      searchKeyUp,
      filterChange,
      totalCount,
      options,
      isSearching,
      formatDate,
      newClick,
      rowClick,
      saveClick,
      dialog,
      model,
      errors,
      offerStatuses,
      customerSearchChanged,
      getOfferStatusColor,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

</style>
