<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Uitgevoerd melden
      </v-card-title>
    </v-row>

    <v-card
      flat
      v-for="(offer, index) in offers"
      :key="'offer_'+index"
      class="mb-8"
    >

      <v-card-title>{{ offer.offer_number }} - {{ offer.customer.name }}</v-card-title>

      <v-data-table
        v-model="selected[offer.id]"
        show-select
        :headers="headers"
        :items="offer.offer_lines"
        :loading="isSearching"
        disable-pagination
        disable-sort
        disable-filtering
        hide-default-footer
        selectable-key="is_scheduled"
      >

        <template v-slot:item.offer_line_status_id="{ item }">
          <v-chip
            small
            dark
            :color="getOfferLineStatusColor(item.offer_line_status_id)"
          >{{ item.offer_line_status.name }}</v-chip>
        </template>

        <template v-slot:item.assembly_date="{ item }">
          {{ formatDate(item.assembly_date) }}
        </template>

        <template v-slot:item.unit_price="{ item }">
          {{ formatCurrency(item.unit_price) }}
        </template>

        <template v-slot:item.price="{ item }">
          {{ formatCurrency(item.price) }}
        </template>

      </v-data-table>

      <v-btn
        color="primary"
        class="mt-6"
        depressed
        dense
        :disabled="!selected[offer.id].length"
        @click="assemblyClick(offer)"
      >
        <v-icon small left>fas fa-truck</v-icon>
        Uitgevoerd
      </v-btn>

    </v-card>


    <v-dialog
      v-model="assemblyDialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Uitgevoerd</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="assemblyDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <p>{{ offerLines.length }} offerteregel(s) geselecteerd</p>

          <v-form
            ref="assemblyForm"
            @submit.prevent
          >

            <date-picker
              v-model="assemblyDate"
              outlined
              label="Montage datum"
              :rules="[value => !!value || 'Montage datum is verplicht', value => /^\d{2}-\d{2}-\d{4}/.test(value) || 'Montage datum heeft een ongeldig formaat']"
            ></date-picker>

            <v-textarea
              outlined
              v-model="offer.assembly_remarks"
              label="Montage opmerking"
            ></v-textarea>

          </v-form>


        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="assemblyCancel()"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="assemblySave()"
          >Uitgevoerd</v-btn>
        </v-card-actions>


      </v-card>

    </v-dialog>

  </v-card>
  
</template>

<script>
import Vue from 'vue'
import { inject, computed, ref, reactive } from '@vue/composition-api'
import DatePicker from '@/components/DatePicker'
import moment from 'moment'
import { formatCurrency, formatDate, getOfferLineStatusColor, OFFER_STATUS_ORDERED, OFFER_LINE_STATUS_SCHEDULED, OFFER_LINE_STATUS_DELIVERED } from '@/utils'

export default {
  components: {
    DatePicker,
  },
  setup(props, context) {
    const api = inject('api')

    const offerCollection = api.resource('offers').collection({
      params: {
        expand: 'offer_lines,customer,offer_lines.supplier,offer_lines.offer_line_status',
        filter: {
          offer_status_id: { eq: OFFER_STATUS_ORDERED },
          offer_line_status_id: { eq: OFFER_LINE_STATUS_SCHEDULED },
        },
      },
      rules: {
        query: 'query:contact_name,company_name,purchase_number,postcode,city,email,phone_number,offer_number',
      },
    })

    offerCollection.all()

    const { filter, isSearching, search } = offerCollection
    const selected = reactive({})

    const offers = computed(() => {

      offerCollection.forEach(offer => {
        Vue.set(selected, offer.id, [])
      })

      return offerCollection.state.items
    })

    const headers = [
      { text: 'Status', value: 'offer_line_status_id', sortable: false },
      { text: 'Montage datum', value: 'assembly_date', sortable: false },
      { text: 'Omschrijving', value: 'description', sortable: false },
      { text: 'Leverancier', value: 'supplier.company_name', sortable: false },
      { text: 'Aantal', value: 'quantity', sortable: false },
      { text: 'Stuksprijs', value: 'unit_price', sortable: false },
      { text: 'Prijs', value: 'price', sortable: false },
    ]

    const assemblyDialog = ref(false)

    const offerModel = api.resource('offers').model()
    const offerLineCollection = api.resource('offer-lines').collection()
    const offerLineModel = api.resource('offer-lines').model()
    const assemblyDate = ref()

    const { attributes: offer } = offerModel
    const { items: offerLines } = offerLineCollection

    function assemblyClick(offer) {
      offerModel.populate(offer)
      offerLineCollection.populate(selected[offer.id])
      assemblyDate.value = moment().format('YYYY-MM-DD')
      assemblyDialog.value = true
    }

    function assemblySave() {
      if (context.refs.assemblyForm.validate()) {

        const models = [offerModel.save()]

        offerLineCollection.forEach(offerLine => {
          offerLineModel.clear()
          offerLineModel.populate(offerLine)
          offerLineModel.setAttributes({
            offer_line_status_id: OFFER_LINE_STATUS_DELIVERED,
            assembly_date: assemblyDate.value,
          })
          models.push(offerLineModel.save())
        })
        Promise.all(models).then(() => {
          offerCollection.all()
          assemblyDialog.value = false
          context.refs.assemblyForm.reset()
        })
      }
    }

    function assemblyCancel() {
      assemblyDialog.value = false
      context.refs.assemblyForm.reset()
    }

    
    return {
      selected,
      headers,
      offers,
      filter,
      search,
      isSearching,
      assemblyDialog,
      assemblyClick,
      assemblySave,
      assemblyCancel,
      formatCurrency,
      offer,
      offerLines,
      assemblyDate,
      getOfferLineStatusColor,
      formatDate,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}


</style>
