<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="isSearching"
    disable-pagination
    disable-sort
    disable-filtering
    hide-default-footer
    v-bind="$attrs"
  >

    <template v-slot:item.offer_line_status_id="{ item }">
      <v-chip
        small
        dark
        :color="getOfferLineStatusColor(item.offer_line_status_id)"
      >{{ item.offer_line_status.name }}</v-chip>
    </template>

    <template v-slot:item.quantity="{ item }">
      {{ formatNumber(item.quantity) }} {{ item.unit }}
    </template>

    <template v-slot:item.unit_price="{ item }">
      {{ formatCurrency(item.unit_price) }}
    </template>

    <template v-slot:item.price="{ item }">
      {{ formatCurrency(item.price) }}
    </template>

  </v-data-table>

</template>

<script>
import { inject, watch } from '@vue/composition-api'
import { getOfferLineStatusColor, formatNumber, formatCurrency } from '@/utils'

export default {
  props: {
    id: Number,
  },
  setup(props) {
    const api = inject('api')

    const offerLineCollection = api.resource('offer-lines').collection({
      params: {
        expand: 'offer,offer_line_status',
        sort: 'sort_index',
      },
      itemsPerPage: null,      
    })

    watch(() => props.id, id => {
      offerLineCollection.setFilter('purchase_order_id', id)
      offerLineCollection.search()
    }, { immediate: true })

    const { items, isSearching } = offerLineCollection

    const headers = [
      { text: 'Status', value: 'offer_line_status_id' },
      { text: 'Offertenummer', value: 'offer.offer_number'},
      { text: 'Omschrijving', value: 'description' },
      { text: 'Aantal', value: 'quantity' },
      { text: 'Stuksprijs', value: 'unit_price' },
      { text: 'Prijs', value: 'price' },
    ]



    return {
      headers,
      items,
      isSearching,
      getOfferLineStatusColor,
      formatNumber,
      formatCurrency,
    }
  },
}
</script>