var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"ma-3 pa-6",attrs:{"flat":""}},[_c('v-row',{staticClass:"pl-0 mb-6 pt-3 pr-3",attrs:{"align":"center"}},[_c('v-card-title',[_vm._v(" Factureren ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"outlined":"","dense":"","placeholder":"Zoek...","append-icon":"fas fa-search","single-line":"","hide-details":"","clearable":"","clear-icon":"fa-times"},on:{"keyup":function($event){return _vm.searchKeyUp()},"click:clear":function($event){return _vm.searchKeyUp()}},model:{value:(_vm.filter.query),callback:function ($$v) {_vm.$set(_vm.filter, "query", $$v)},expression:"filter.query"}})],1),_vm._l((_vm.offers),function(offer,index){return _c('v-card',{key:'offer_'+index,staticClass:"mb-8",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(_vm._s(offer.offer_number)+" - "+_vm._s(offer.customer.name))]),_c('v-data-table',{attrs:{"show-select":"","headers":_vm.headers,"items":offer.offer_lines,"loading":_vm.isSearching,"disable-pagination":"","disable-sort":"","disable-filtering":"","hide-default-footer":"","selectable-key":"is_delivered"},scopedSlots:_vm._u([{key:"item.offer_line_status_id",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","dark":"","color":_vm.getOfferLineStatusColor(item.offer_line_status_id)}},[_vm._v(_vm._s(item.offer_line_status.name))])]}},{key:"item.assembly_date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.assembly_date))+" ")]}},{key:"item.unit_price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.unit_price))+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatCurrency(item.price))+" ")]}}],null,true),model:{value:(_vm.selected[offer.id]),callback:function ($$v) {_vm.$set(_vm.selected, offer.id, $$v)},expression:"selected[offer.id]"}}),_c('v-btn',{staticClass:"mt-6",attrs:{"color":"primary","depressed":"","dense":"","disabled":!_vm.selected[offer.id].length},on:{"click":function($event){return _vm.billingClick(offer)}}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("fas fa-truck")]),_vm._v(" Factureren ")],1)],1)}),_c('v-dialog',{attrs:{"max-width":"700","persistent":""},model:{value:(_vm.billingDialog),callback:function ($$v) {_vm.billingDialog=$$v},expression:"billingDialog"}},[_c('v-card',[_c('v-toolbar',{staticClass:"px-2",attrs:{"color":"title","dark":"","flat":"","short":""}},[_c('v-toolbar-title',[_vm._v("Factureren")]),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.billingDialog = false}}},[_c('v-icon',[_vm._v("fas fa-times")])],1)],1),_c('v-card-text',{staticClass:"mt-12"},[_c('p',[_vm._v(_vm._s(_vm.offerLines.length)+" offerteregel(s) geselecteerd")])]),_c('v-card-actions',{staticClass:"px-6 pb-8"},[_c('v-spacer'),_c('v-btn',{staticClass:"px-5",attrs:{"color":"primary","large":"","outlined":"","depressed":""},on:{"click":function($event){_vm.billingDialog = false}}},[_vm._v("Annuleren")]),_c('v-btn',{staticClass:"px-5",attrs:{"color":"primary","large":"","depressed":""},on:{"click":function($event){return _vm.billingSave()}}},[_vm._v("Factureren")])],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }