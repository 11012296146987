<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Leveranciers
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Zoek..."
        append-icon="fas fa-search"
        single-line
        hide-details
        @keyup="searchKeyUp()"
      ></v-text-field>
      <v-btn
        class="ml-4"
        depressed
        color="primary"
        @click="newClick"
      >
    <v-icon
      small
      left
    >fas fa-folder-plus</v-icon>
    Nieuw
    </v-btn>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >
    
      <template v-slot:item.action="{ item }">
        <v-icon
          small
          @click.stop="$refs.confirmDelete.show(item.id)"
        >fas fa-trash</v-icon>
      </template>

    </v-data-table>

    <ConfirmDialog
      ref="confirmDelete"
      title="Verwijderen"
      labelConfirm="Verwijderen"
      labelConfirmColor="error"
      @confirm="deleteClick"
    ></ConfirmDialog>


    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Leverancier</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>

            <v-text-field
              outlined
              v-model="model.company_name"
              :error-messages="errors.company_name"
              label="Leveranciersnaam"
            ></v-text-field>

            <v-text-field
              outlined
              v-model="model.contact_name"
              :error-messages="errors.contact_name"
              label="Contactpersoon"
            ></v-text-field>

            <v-text-field
              outlined
              v-model="model.address"
              :error-messages="errors.address"
              label="Adres (straat + huisnr)"
            ></v-text-field>

            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  v-model="model.postcode"
                  :error-messages="errors.postcode"
                  label="Postcode"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  v-model="model.city"
                  :error-messages="errors.city"
                  label="Woonplaats"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              outlined
              v-model="model.phone_number"
              :error-messages="errors.phone_number"
              label="Telefoonnummer"
            ></v-text-field>

            <v-text-field
              outlined
              v-model="model.mobile_number"
              :error-messages="errors.mobile_number"
              label="Mobiel"
            ></v-text-field>

            <v-text-field
              outlined
              v-model="model.email"
              :error-messages="errors.email"
              label="E-mail"
            ></v-text-field>

            <v-textarea
              outlined
              v-model="model.remarks"
              :error-messages="errors.remarks"
              label="Notitie"
            ></v-textarea>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Opslaan</v-btn>
        </v-card-actions>


      </v-card>





    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import ConfirmDialog from '@/components/ConfirmDialog'
import { scrollToFirstError } from '@/utils'

export default {
  components: {
    ConfirmDialog,
  },
  setup() {
    const api = inject('api')
    const loader = inject('loader')

    const supplierCollection = api.resource('suppliers').collection({
      rules: {
        query: 'query:company_name,contact_name,address,city,email,phone_number,mobile_number',
      }
    })

    const { items, filter, totalCount, options, isSearching } = supplierCollection

    const headers = [
      { text: 'Leverancier', value: 'company_name' },
      { text: 'Contactpersoon', value: 'contact_name' },
      { text: 'E-mailadres', value: 'email' },
      { text: 'Adres', value: 'address' },
      { text: 'Woonplaats', value: 'city' },
      { text: 'Telefoonnummer', value: 'phone_number' },
      { text: '', value: 'action', align: 'center' },
    ]

    const dialog = ref(false)

    const supplierModel = api.resource('suppliers').model()
    const { attributes: model, errors, isBusy } = supplierModel

    loader.bindRef(isBusy)

    function newClick() {
      supplierModel.clear()
      dialog.value = true
    }

    function rowClick(item) {
      supplierModel.find(item.id).then(() => {
        dialog.value = true
      })
    }

    function saveClick() {
      supplierModel.save().then(() => {
        supplierCollection.search()
        dialog.value = false
      }).catch(() => {
        scrollToFirstError()
      })
    }

    function deleteClick(id) {
      api.resource('suppliers').remove(id).then(() => {
        supplierCollection.search()
      })
    }

    const searchKeyUp = debounce(supplierCollection.search, 300)

    return {
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      isSearching,
      newClick,
      rowClick,
      saveClick,
      deleteClick,
      dialog,
      model,
      errors,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

</style>
