import { computed } from '@vue/composition-api'


export const OFFER_STATUS_NEW      = 10 
export const OFFER_STATUS_MEASURE  = 20
export const OFFER_STATUS_CHECK    = 30
export const OFFER_STATUS_OFFERED  = 40
export const OFFER_STATUS_REVIEW   = 50
export const OFFER_STATUS_OFFERED2 = 55
export const OFFER_STATUS_AGREED   = 60
export const OFFER_STATUS_ORDERED  = 65
export const OFFER_STATUS_REJECTED = 70
export const OFFER_STATUS_DONE     = 80


export const OFFER_LINE_STATUS_NEW        = 10
export const OFFER_LINE_STATUS_TO_BUY     = 20
export const OFFER_LINE_STATUS_ORDERED    = 30
export const OFFER_LINE_STATUS_RECEIVED   = 40
export const OFFER_LINE_STATUS_SCHEDULED  = 50
export const OFFER_LINE_STATUS_DELIVERED  = 60
export const OFFER_LINE_STATUS_BILLED     = 70
export const OFFER_LINE_STATUS_DONE       = 80 // not used anymore


export const activeStatuses = [
  OFFER_STATUS_NEW,
  OFFER_STATUS_MEASURE,
  OFFER_STATUS_CHECK,
  OFFER_STATUS_OFFERED,
  OFFER_STATUS_REVIEW,
];


export function getOfferStatusColor(offer_status_id) {
  switch(offer_status_id) {
    // case OFFER_STATUS_CHECK: return '#C6212B' // check
    // case OFFER_STATUS_OFFERED: return '#DE6F2A' // offered
    // case OFFER_STATUS_REVIEW: return '#024877' // review
    // case OFFER_STATUS_AGREED: return '#297614' // agreed
    // case OFFER_STATUS_ORDERED: return '#297614' // ordered
    // case OFFER_STATUS_REJECTED: return '#5A5A5A' // rejected
    // case OFFER_STATUS_DONE: return '#000000' // done
    case OFFER_STATUS_NEW: return 'purple' // check
    case OFFER_STATUS_MEASURE: return 'grey' // measure
    case OFFER_STATUS_CHECK: return 'red' // check
    case OFFER_STATUS_OFFERED: return 'orange' // offered
    case OFFER_STATUS_REVIEW: return 'blue' // review
    case OFFER_STATUS_OFFERED2: return 'pink' // offered2
    case OFFER_STATUS_AGREED: return 'green' // agreed
    case OFFER_STATUS_ORDERED: return 'green' // ordered
    case OFFER_STATUS_REJECTED: return 'black' // rejected
    case OFFER_STATUS_DONE: return '#FFD600' // done yellow accent-4
    default:
  }

  return '#000000'
}

export function getOfferLineStatusColor(offer_line_status_id, is_long_delivery_time) {
  switch(offer_line_status_id) {
    case OFFER_LINE_STATUS_ORDERED: return is_long_delivery_time ? 'red' : '#F77C2F'
    case OFFER_LINE_STATUS_RECEIVED: return '#297614'
    case OFFER_LINE_STATUS_SCHEDULED: return '#26B3FB'
    default:
  }
  return '#7F7F7F'
}


export function useOffer(offerModel) {

  const isRejected = computed(() => {
    return offerModel.getAttribute('offer_status_id') === OFFER_STATUS_REJECTED
  })

  const isOldRejected = computed(() => {
    return offerModel.getOldAttribute('offer_status_id') === OFFER_STATUS_REJECTED
  })

  const getOfferStatusColor = computed(() => {
    return getOfferStatusColor(offerModel.getAttribute('offer_status_id'))
  })

  // is active offer
  const isActive = computed(() => {
    return offerModel.getOldAttribute('is_active')
  })

  // is active offer editable
  const isActiveEditable = computed(() => {
    return offerModel.getOldAttribute('is_active_editable')
  })

  // is active offer and editable
  const isEditable = computed(() => {
    return offerModel.getOldAttribute('is_editable')
  })

  const hasDiscountTypePercentage = computed(() => {
    return offerModel.getAttribute('discount_type') === 'percentage'
  })

  const hasDiscountTypeAmount = computed(() => {
    return offerModel.getAttribute('discount_type') === 'amount'
  })

  const hasNoDiscount = computed(() => {
    return offerModel.getAttribute('discount_type') !== 'percentage' && offerModel.getAttribute('discount_type') !== 'amount'
  })

  const hasOldNoDiscount = computed(() => {
    return offerModel.getOldAttribute('discount_type') !== 'percentage' && offerModel.getOldAttribute('discount_type') !== 'amount'
  })


  return {
    isRejected,
    isOldRejected,
    getOfferStatusColor,

    isActive,
    isEditable,
    isActiveEditable,

    hasDiscountTypePercentage,
    hasDiscountTypeAmount,
    hasNoDiscount,
    hasOldNoDiscount,
  }

}

export function useOfferLine(offerLineModel, offerLineProductModel) {

  const getOfferLineStatusColor = computed(() => {
    return getOfferLineStatusColor(offerLineModel.getAttribute('offer_line_status_id'))
  })

  const isCurtain = computed(() => offerLineModel.getAttribute('offer_line_type_id') == 1)
  const isRomanBlind = computed(() => offerLineModel.getAttribute('offer_line_type_id') == 2)
  const isRollerBlind = computed(() => offerLineModel.getAttribute('offer_line_type_id') == 3)
  const isBlind = computed(() => offerLineModel.getAttribute('offer_line_type_id') == 4)
  const isPleatedBlind = computed(() => offerLineModel.getAttribute('offer_line_type_id') == 5)
  const isScreen = computed(() => offerLineModel.getAttribute('offer_line_type_id') == 6)
  const isProduct = computed(() => offerLineModel.getAttribute('offer_line_type_id') == 7)

  const isBeforeStatusOrdered = computed(() => offerLineModel.getOldAttribute('offer_line_status_id') < OFFER_LINE_STATUS_ORDERED)

  const price = computed(() => offerLineModel.getAttribute('quantity', 0) * offerLineModel.getAttribute('unit_price', 0))

  const isDistributionEqual = computed(() => offerLineProductModel.getAttribute('distribution_type') == 'Twee gelijke delen')
  const isDistributionUnequal = computed(() => offerLineProductModel.getAttribute('distribution_type') == 'Twee ongelijke delen')
  const isDistributionOther = computed(() => offerLineProductModel.getAttribute('distribution_type') == 'Anders')
  const isDistributionLeft = computed(() => offerLineProductModel.getAttribute('distribution_type') == '1 stuk links')
  const isDistributionRight = computed(() => offerLineProductModel.getAttribute('distribution_type') == '1 stuk rechts')

  const isPleatTypeSingle = computed(() => offerLineProductModel.getAttribute('pleat_type') == 'Enkel')
  const isPleatTypeWave = computed(() => offerLineProductModel.getAttribute('pleat_type') == 'Wavegordijn')

  const isMountingOnTheDay = computed(() => offerLineProductModel.getAttribute('mounting_type') == 'Op de dag')
  const isMountingCoveMoldingInTheDay = computed(() => offerLineProductModel.getAttribute('mounting_cove_molding') == 'In de dag')
  const isMountingCoveMoldingOnTheDay = computed(() => offerLineProductModel.getAttribute('mounting_cove_molding') == 'Op de dag')

  const isFabricTypeWidth = computed(() => offerLineProductModel.getAttribute('fabric_type') == 'Breedte')
  const isFabricTypeHeight = computed(() => offerLineProductModel.getAttribute('fabric_type') == 'Hoogte')

  const isSystemUnrollContra = computed(() => offerLineProductModel.getAttribute('system_unroll') == 'Contra')
  const hasSystemLadderCord = computed(() => !!offerLineProductModel.getAttribute('system_ladder_cord'))


  return {
    isCurtain,
    isRomanBlind,
    isRollerBlind,
    isBlind,
    isPleatedBlind,
    isScreen,
    isProduct,

    isBeforeStatusOrdered,

    getOfferLineStatusColor,

    price,
    
    isDistributionEqual,
    isDistributionUnequal,
    isDistributionOther,
    isDistributionLeft,
    isDistributionRight,

    isPleatTypeSingle,
    isPleatTypeWave,

    isMountingOnTheDay,
    isMountingCoveMoldingInTheDay,
    isMountingCoveMoldingOnTheDay,

    isFabricTypeWidth,
    isFabricTypeHeight,

    isSystemUnrollContra,
    hasSystemLadderCord,
  }

}

