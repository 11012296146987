<template>
  <div>
    <v-breadcrumbs
      class="pt-3 pl-7"
      :items="[{ text: 'Klanten', exact: true,  to: { name: 'customers' } }, { text: model.name }]"
    >
    </v-breadcrumbs>
    <div class="mx-3">
      <v-toolbar
        flat
        dark
        color="primary"
        dense
      >

        <v-tabs
          v-model="tab"
        >
          <v-tabs-slider></v-tabs-slider>
          <v-tab>Klantgegevens</v-tab>
          <v-tab>Offertes</v-tab>
        </v-tabs>
      </v-toolbar>
      <v-tabs-items
        v-model="tab"
      >
        <v-tab-item><customer-data></customer-data></v-tab-item>
        <v-tab-item><customer-offers></customer-offers></v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { inject, provide, ref, watch } from '@vue/composition-api'
import CustomerData from './customer/Data'
import CustomerOffers from './customer/Offers'

export default {
  components: {
    CustomerData,
    CustomerOffers,
  },
  setup(props, context) {
    const api = inject('api')

    const tab = ref(0)

    const customerModel = api.resource('customers').model()
    const { attributes: model } = customerModel

    const offerCollection = api.resource('offers').collection()

    provide('customerModel', customerModel)
    provide('offerCollection', offerCollection)

    watch(() => context.root.$route.params.id, id => {
      customerModel.find(id)
      offerCollection.search({ filter: { customer_id: { eq: id }}})
    }, { immediate: true })

    return {
      tab,
      model,
    }
  },
}
</script>