<template>
  <v-input hide-details>
    <label style="position:absolute;top:-10px;font-size:12px;left:12px">{{ label }}</label>
    <v-btn-toggle
      v-model="model"
      class="mt-2"
      style="width:100%;height:100%"
      v-bind="$attrs"
    >
      <v-btn
        v-for="(item, index) in items"
        :key="'curtain_type'+index"
        :width="String(100 / items.length) + '%'"
        :value="item[itemValue]"
        v-text="item[itemText]"
        :disabled="disabled"
        :color="item[itemValue] == model ? 'primary' : 'default'"
        :class="item[itemValue] == model ? 'white--text' : ''"
      ></v-btn>

    </v-btn-toggle>
  </v-input>
</template>

<script>
import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    label: String,
    value: [String, Number],
    items: Array,
    itemText: { type: String, default: 'text' },
    itemValue: { type: String, default: 'value' },
    nullValue: { default: null },
    disabled: { type: Boolean, default: false },
  },
  setup(props, context) {
    
    let model = ref(props.value)

    watch(() => props.value, val => {
      model.value = val
    })

    watch(model, val => {
      
      if (val === undefined) {
        val = props.nullValue
      }

      if (props.value !== val) {
        context.emit('input', val)
        context.emit('change', val)
      }
    })

    return {
      model,
    }
  },
}
</script>