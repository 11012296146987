<template>
  <div>
    <v-breadcrumbs
      v-show="offerLine.description && offer.id"
      class="pt-3 pl-7"
      :items="[{ text: 'Offertes', exact: true,  to: { name: 'offers' } }, { text: offer.offer_number, exact: true, to: { name: 'offer', params: { id: offer.id }} }, { text: offerLine.description }]"
    >
    </v-breadcrumbs>

    <offer-line-curtain
      v-if="isCurtain"
    ></offer-line-curtain>

    <offer-line-roman-blind
      v-if="isRomanBlind"
    ></offer-line-roman-blind>

    <offer-line-roller-blind
      v-if="isRollerBlind"
    ></offer-line-roller-blind>

    <offer-line-blind
      v-if="isBlind"
    ></offer-line-blind>

    <offer-line-pleated-blind
      v-if="isPleatedBlind"
    ></offer-line-pleated-blind>

    <offer-line-screen
      v-if="isScreen"
    ></offer-line-screen>

    <offer-line-product
      v-if="isProduct && !isNewOfferLine"
    ></offer-line-product>
  
    <offer-line-products
      v-if="isProduct && isNewOfferLine"
    ></offer-line-products>

  </div>
</template>

<script>
import { inject, provide, watch } from '@vue/composition-api'
import OfferLineCurtain from './offerLine/OfferLineCurtain'
import { useOfferLine } from '@/utils'
import OfferLineRomanBlind from './offerLine/OfferLineRomanBlind'
import OfferLineRollerBlind from './offerLine/OfferLineRollerBlind'
import OfferLineBlind from './offerLine/OfferLineBlind'
import OfferLinePleatedBlind from './offerLine/OfferLinePleatedBlind'
import OfferLineScreen from './offerLine/OfferLineScreen'
import OfferLineProduct from './offerLine/OfferLineProduct'
import OfferLineProducts from './offerLine/OfferLineProducts'


export default {
  components: {
    OfferLineCurtain,
    OfferLineRomanBlind,
    OfferLineRollerBlind,
    OfferLineBlind,
    OfferLinePleatedBlind,
    OfferLineScreen,
    OfferLineProduct,
    OfferLineProducts,
  },
  setup(props, context) {
    
    const api = inject('api')
    const loader = inject('loader')

    // offerLine
    const offerLineModel = api.resource('offer-lines').model({
      params: {
        // expand: 'offer',
      },
    })
    provide('offerLineModel', offerLineModel)
    loader.bindRef(offerLineModel.isSaving)

    // offer
    const offerModel = api.resource('offers').model()
    provide('offerModel', offerModel)

    // offerLineProduct
    const offerLineProductModel = api.resource('offer-line-products').model()
    provide('offerLineProductModel', offerLineProductModel)
    loader.bindRef(offerLineProductModel.isSaving)

    // offerLineType
    const offerLineTypeModel = api.resource('offer-line-types').model()
    provide('offerLineTypeModel', offerLineTypeModel)

    // suppliers
    provide('suppliers', api.resource('suppliers').collection().all({ sort: 'company_name' }))

    // watch
    watch(() => context.root.$route.params, params => {
      if (params.id) {
        Promise.all([
          offerLineModel.find(params.id),
          offerLineProductModel.find(params.id),
        ]).then(responses => {
          let offerLine = responses[0].data
          offerModel.find(offerLine.offer_id)
          offerLineTypeModel.find(offerLine.offer_line_type_id)
        }).catch(() => {
          context.root.$router.push({
            name: 'offers',
          })
        })

      } else if (params.offer_id && params.offer_line_type_id) {

        Promise.all([
          offerModel.find(params.offer_id),
          offerLineTypeModel.find(params.offer_line_type_id)
        ]).then(responses => {
          let offer = responses[0].data
          let offerLineType = responses[1].data
          offerLineModel.clear()
          offerLineModel.setAttributes({
            offer_id: offer.id,
            offer_line_type_id: offerLineType.id,
            description: offerLineType.name,
            quantity: 1,
          })
          offerLineProductModel.clear()
          offerLineProductModel.setAttributes({
            fabric_rapport_height: 0,
            curtain_hem: offerLineType.curtain_hem,
            confection_price: offerLineType.confection_price,
            confection_min_surface: offerLineType.confection_min_surface,
          })
        }).catch(() => {
          context.root.$router.push({
            name: 'offers',
          })
        })

      } else {
        context.root.$router.push({
          name: 'offers',
        })

      }

    }, { immediate: true })

    const { attributes: offer } = offerModel
    const { attributes: offerLine, isNewRecord: isNewOfferLine } = offerLineModel
    const { isCurtain, isRomanBlind, isRollerBlind, isBlind, isPleatedBlind, isScreen, isProduct } = useOfferLine(offerLineModel)


    return {
      isNewOfferLine,
      offer,
      offerLine,
      isCurtain,
      isRomanBlind,
      isRollerBlind,
      isBlind,
      isPleatedBlind,
      isScreen,
      isProduct,
    }

  },
}
</script>


