<template>
  <v-simple-checkbox
    v-ripple
    v-model="model"
    v-bind="$attrs"
  ></v-simple-checkbox>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'

export default defineComponent({
  props: {
    value: {},
    trueValue: { default: true },
    falseValue: { default: false },
  },
  setup(props, context) {

    const model = ref()

    watch(() => props.value, v => {
      model.value = (v === props.trueValue)
    }, { immediate: true })

    watch(model, v => {
      context.emit('input', v ? props.trueValue : props.falseValue)
    })

    return {
      model,
    }

  },
})
</script>
