<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Artikelen in te kopen
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Zoek..."
        append-icon="fas fa-search"
        single-line
        hide-details
        clearable
        clear-icon="fa-times"
        @keyup="searchKeyUp()"
        @click:clear="searchKeyUp()"
      ></v-text-field>
      <v-select
        outlined
        dense
        single-line
        hide-details
        clearable
        clear-icon="fa-times"
        v-model="filter.supplier_id"
        :items="suppliers"
        item-value="id"
        item-text="company_name"
        @change="search()"
        @click:clear="search()"
        placeholder="Leverancier"
      ></v-select>
    </v-row>

    <v-data-table
      v-model="selected"
      show-select
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >

      <template v-slot:item.unit_price="{ item }">
        {{ formatCurrency(item.unit_price) }}
      </template>

      <template v-slot:item.price="{ item }">
        {{ formatCurrency(item.price) }}
      </template>

    </v-data-table>

    <v-btn
      color="primary"
      depressed
      :disabled="!selected.length"
      dense
      @click="purchaseClick()"
    >
      <v-icon small left>fas fa-truck</v-icon>
      Inkopen
    </v-btn>

    <v-dialog
      v-model="purchaseDialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Inkopen</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="purchaseDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>

            <date-picker
              v-model="purchaseOrder.purchase_date"
              :error-messages="purchaseOrderErrors.purchase_date"
              outlined
              label="Besteldatum"
            ></date-picker>

            <date-picker
              v-model="purchaseOrder.delivery_date"
              :error-messages="purchaseOrderErrors.delivery_date"
              outlined
              label="Verwachte leverdatum"
            ></date-picker>

            <v-text-field
              outlined
              v-model="purchaseOrder.purchase_number"
              :error-messages="purchaseOrderErrors.purchase_number"
              label="Inkoopnummer"
            ></v-text-field>

            <v-select
              outlined
              v-model="purchaseOrder.supplier_id"
              :error-messages="purchaseOrderErrors.supplier_id"
              :items="suppliers"
              item-text="company_name"
              item-value="id"
              label="Leverancier"
            ></v-select>


          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="purchaseDialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="purchaseSave()"
          >Inkopen</v-btn>
        </v-card-actions>


      </v-card>





    </v-dialog>


    <v-dialog
      v-model="supplierDialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Offerteregel</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="supplierDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>

            <v-select
              outlined
              v-model="offerLine.supplier_id"
              :error-messages="offerLineErrors.supplier_id"
              :items="suppliers"
              item-text="company_name"
              item-value="id"
              label="Leverancier"
            ></v-select>


          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="supplierDialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="supplierSave()"
          >Opslaan</v-btn>
        </v-card-actions>


      </v-card>

    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import DatePicker from '@/components/DatePicker'
import { formatCurrency, OFFER_STATUS_ORDERED, OFFER_LINE_STATUS_TO_BUY, OFFER_LINE_STATUS_ORDERED } from '@/utils'

export default {
  components: {
    DatePicker,
  },
  setup() {
    const api = inject('api')

    const offerLineCollection = api.resource('offer-lines').collection({
      params: {
        expand: 'offer,offer.customer,supplier,offer_line_status',
        filter: {
          offer_status_id: { eq: OFFER_STATUS_ORDERED },
          offer_line_status_id: { eq: OFFER_LINE_STATUS_TO_BUY }
        },
      },
      rules: {
        query: 'query:contact_name,company_name',
      },
    })

    const purchaseOrderModel = api.resource('purchase-orders').model()

    const { attributes: purchaseOrder, errors: purchaseOrderErrors } = purchaseOrderModel

    const suppliers = api.resource('suppliers').collection({
      params: {
        sort: 'company_name'
      }
    }).all()

    const { items, filter, totalCount, options, isSearching, search } = offerLineCollection

    const headers = [
      { text: 'Offertenummer', value: 'offer.offer_number', sortable: false },
      { text: 'Klantnaam', value: 'offer.customer.name', sortable: false },
      { text: 'Omschrijving', value: 'description', sortable: false },
      { text: 'Leverancier', value: 'supplier.company_name', sortable: false },
      { text: 'Aantal', value: 'quantity', sortable: false },
      { text: 'Stuksprijs', value: 'unit_price', sortable: false },
      { text: 'Prijs', value: 'price', sortable: false },
    ]

    const selected = ref([])

    const purchaseDialog = ref(false)
    const supplierDialog = ref(false)

    const offerLineModel = api.resource('offer-lines').model()
    const { attributes: offerLine, errors: offerLineErrors } = offerLineModel

    function purchaseClick() {
      purchaseOrderModel.clear()
      selected.value.forEach(offerLine => {
        if (offerLine.supplier_id) {
          purchaseOrderModel.setAttribute('supplier_id', offerLine.supplier_id)
        }
      })
      purchaseDialog.value = true
    }

    function purchaseSave() {
      purchaseOrderModel.save().then(({ data: purchaseOrder }) => {
        const offerLineModels = []
        selected.value.forEach(offerLine => {
          offerLineModel.clear()
          offerLineModel.populate(offerLine)
          offerLineModel.setAttributes({
            offer_line_status_id: OFFER_LINE_STATUS_ORDERED,
            purchase_order_id: purchaseOrder.id,
          })
          offerLineModels.push(offerLineModel.save())
        })
        Promise.all(offerLineModels).then(() => {
          offerLineCollection.search()
          purchaseDialog.value = false
          selected.value = []
        })
      })
    }

    const searchKeyUp = debounce(offerLineCollection.search, 300)

    function rowClick(offerLine) {
      offerLineModel.clear()
      offerLineModel.populate(offerLine)
      supplierDialog.value = true
    }

    function supplierSave() {
      offerLineModel.save().then(() => {
        offerLineCollection.search()
        supplierDialog.value = false
      })
    }

    return {
      selected,
      headers,
      items,
      filter,
      search,
      totalCount,
      options,
      isSearching,
      purchaseDialog,
      purchaseClick,
      purchaseSave,
      supplierDialog,
      rowClick,
      supplierSave,
      suppliers,
      purchaseOrder,
      purchaseOrderErrors,
      offerLine,
      offerLineErrors,
      formatCurrency,
      searchKeyUp,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}


</style>
