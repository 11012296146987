<template>
  <v-dialog
    v-model="dialog"
    v-bind="$attrs"
  >
    <v-card>
      <v-toolbar color="title" dark flat short class="px-2">
        <v-toolbar-title>Verstuur offerte</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
      </v-toolbar>

      <v-card-text class="mt-12">

        <v-form @submit.prevent
          class="mt-6"
        >

          <v-text-field
            outlined
            v-model="mailOffer.email"
            :error-messages="mailOfferErrors.email"
            label="Email"
          ></v-text-field>

          <date-picker
            v-model="mailOffer.reminder_date"
            :error-messages="mailOfferErrors.reminder_date"
            outlined
            label="Herinneringsdatum"
          ></date-picker>

          <v-select
            outlined
            v-model="mailOffer.mail_template_id"
            :items="mailTemplates"
            item-value="id"
            item-text="description"
            label="Template"
            @change="selectTemplate"
          ></v-select>

          <v-text-field
            outlined
            v-model="mailOffer.subject"
            :error-messages="mailOfferErrors.subject"
            label="Onderwerp"
          ></v-text-field>


          <v-card
            class="mb-6"
            flat
            outlined
            @drop.prevent="dropFiles"
            @dragenter.prevent
            @dragover.prevent
          >

            <v-list-item
              dense
            >
              <v-list-item-content>
                Offerte.pdf
              </v-list-item-content>
            </v-list-item>


            <v-list-item
              v-for="(file, index) in mailOffer.attachments"
              :key="index"
              dense
            >
              <v-list-item-content>
                {{ file.name }} ({{ Math.round(file.size / 1024, 0) }} kB)
                <v-btn
                  absolute
                  top
                  right
                  style="margin-top:-10px"
                  icon
                  small
                  color="error"
                  @click.stop="removeFile(index)"
                ><v-icon small>fas fa-times</v-icon></v-btn>
              </v-list-item-content>
            </v-list-item>

            <div class="text-center">
              <v-btn
                outlined
                color="primary"
                class="my-3"
                depressed
                small
                @click="browseFiles()"
              >Bijlage toevoegen</v-btn>
            </div>

            <input
              ref="fileBrowser"
              type="file"
              multiple
              @change="addBrowsedFiles"
              hidden
            />

          </v-card>



          <editor-field
            v-model="mailOffer.body"
            :error-messages="mailOfferErrors.body"
          ></editor-field>
          

        </v-form>
      </v-card-text>

      <v-card-actions class="px-6 pb-8">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          class="px-5"
          large
          outlined
          depressed
          @click="dialog = false"
        >Annuleren</v-btn>
        <v-btn
          color="primary"
          class="px-5"
          large
          depressed
          @click="sendClick()"
        >Verzenden</v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>

</template>


<script>
import { inject, ref, watch } from '@vue/composition-api'
import DatePicker from '@/components/DatePicker'
import EditorField from '@/components/EditorField'
import { readFileAsDataUrl } from '@/utils'


export default {
  components: {
    DatePicker,
    EditorField,
  },
  props: {
    value: Boolean,
  },
  setup(props, context) {
    const api = inject('api')
    const loader = inject('loader')

    const offerModel = inject('offerModel')
    const mailTemplates = inject('mailTemplates')
    const mailOfferModel = api.resource().model()

    loader.bindRef(mailOfferModel.isCreating)

    const dialog = ref()

    watch(() => props.value, val => {
      dialog.value = val
      if (val) {
        mailOfferModel.clear()
        mailOfferModel.setAttribute('email', offerModel.getAttribute('customer', {}).email)
        let d = new Date()
        d.setTime(d.getTime() + 7 * 1000 * 3600 * 24)

        mailOfferModel.setAttribute('reminder_date', d.toISOString().substr(0, 10))
      }
    }, { immediate: true })

    watch(dialog, val => {
      context.emit('input', val)
    })

    const { attributes: mailOffer, errors: mailOfferErrors } = mailOfferModel

    function addBrowsedFiles(event) {
      addFiles(event.target.files)
    }

    function addFiles(files) {
      files.forEach(file => {
        readFileAsDataUrl(file).then(data => {
          const attachments = mailOfferModel.getAttribute('attachments', [])
          attachments.push({
            name: file.name,
            size: file.size,
            type: file.type,
            data,
          })
          mailOfferModel.setAttribute('attachments', attachments)
        })
      })
    }

    function browseFiles() {
      context.refs.fileBrowser.click()
    }

    function dropFiles(event) {
      addFiles(Array.from(event.dataTransfer.files))
    }

    function removeFile(index) {
      const attachments = mailOfferModel.getAttribute('attachments', [])
      attachments.splice(index, 1)
      mailOfferModel.setAttribute('attachments', attachments)
    }

    function selectTemplate(mailTemplateId) {
      // console.log(mailTemplates)
      loader.show()
      api.resource('mail-templates').get(`${mailTemplateId}/offer/${offerModel.getAttribute('id')}`).then(({ data: mailTemplate }) => {
        mailOfferModel.setAttributes({
          subject: mailTemplate.subject,
          body: mailTemplate.body,
        })
      }).finally(() => {
        loader.hide()
      })
    }

    function sendClick() {
      mailOfferModel.setResource(`offers/${offerModel.getAttribute('id')}/mail`)
      mailOfferModel.create().then(() => {
        context.emit('update')
        dialog.value = false
      })
    }


    return {
      dialog,
      mailTemplates,
      mailOffer,
      mailOfferErrors,
      addBrowsedFiles,
      browseFiles,
      dropFiles,
      removeFile,
      selectTemplate,
      sendClick,
    }


    
  },
}
</script>
