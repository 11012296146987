<template>
  <v-text-field
    v-model="model"
    v-bind="$attrs"
    v-on="listeners"
    @keypress="validateNumber"
    @change="updateValue"
  >
    <slot v-for="(_, name) in $slots" :name="name" :slot="name" />  
  </v-text-field>
</template>

<script>
// https://gist.github.com/loilo/73c55ed04917ecf5d682ec70a2a1b8e2

import { ref, watch } from '@vue/composition-api'

export default {
  props: {
    value: [Number, String],
    decimalSeparator: {
      type: String,
      default: ',',
      validator: (value) => value.length === 1,
    },
    decimals: {
      default: null,
    },
    integer: {
      type: Boolean,
      default: false,
    },
    absolute: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {

    const model = ref()

    watch(() => props.value, value => {
      model.value = santizeValue(value)
    }, { immediate: true })

    const listeners = Object.assign({}, context.listeners)
    delete listeners.input
    delete listeners.keypress
    delete listeners.change

    function updateValue(stringValue) {

      // console.log('update', stringValue)

      // replace locale decimal separator with dot
      stringValue = stringValue.replace(props.decimalSeparator.charAt(0), '.')

      // sanitize value
      stringValue = santizeValue(stringValue)

      let numberNewValue = stringValue.length ? Number(stringValue.replace(props.decimalSeparator.charAt(0), '.')) : null
      let numberOldValue = props.value !== null ? Number(props.value) : null

      // console.log('stringValue', stringValue, 'numberOldValue', numberOldValue, 'numberNewValue', numberNewValue)

      if (numberNewValue !== numberOldValue) {
        // console.log('changed model')

        context.emit('input', numberNewValue)
        context.emit('change', numberNewValue)

      } else {
        // console.log('update model')

        model.value = stringValue        
      }
    }

    function santizeValue(value) {

      // remove all dirty characters
      value = String(value).replace(/[^\d.-]/g, '')

      // find first dot
      let firstDotPosition = value.indexOf('.')

      // remove additional dots
      if (firstDotPosition !== -1) {
        value = value.substr(0, firstDotPosition + 1) + value.substr(firstDotPosition + 1).replace(/\./g, '')
      }

      // remove additional minus signs
      if (value.length > 1) {
        value = value.substr(0, 1) + value.substr(1).replace(/-/g, '')
      }

      // remove minus sign when absolute
      if (props.absolute) {
        value = value.replace(/-/, '')
      }

      // return invalid value as empty string
      if (value.length === 0 || value === '-' || value === '-.' || value.charAt(0) === '.') {
        return ''
      }

      // remove ending dot
      if (value.slice(-1) === '.') {
        value = value.slice(0, -1)
      }

      if (props.integer) {
        return String(Math.floor(Number(value)))
      }

      return props.decimals !== null
        ? Number(value).toFixed(props.decimals).replace(/\./, props.decimalSeparator.charAt(0))
        : String(Number(value)).replace(/\./, props.decimalSeparator.charAt(0))
    }

    // function isFiniteNumber(value) {
    //   return typeof value === 'number' && isFinite(value)
    // }

    function validateNumber(e) {
      const charSeparator = props.decimalSeparator.charAt(0)
      const charCodeSeparator = charSeparator.charCodeAt(0)

      let value = e.target.value

      // minus at first position
      if (!props.absolute && e.which === 45 && e.target.selectionStart === 0) {
        return true
      }

      // only one occurrance of separator
      if (!props.integer && e.which === charCodeSeparator && value.length && value.indexOf(charSeparator) === -1) {
        return true
      }

      // todo: separator at first position or second with minus, prepend with '0'

      // digits
      if (e.which >= 48 && e.which <= 57) {
        return true
      }

      e.preventDefault()

      context.emit('keypress', e)
    }


    return {
      model,
      listeners,
      validateNumber,
      updateValue,
    }

  }
}
</script>
