<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Artikelgroepen
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Zoek..."
        append-icon="fas fa-search"
        single-line
        hide-details
        @keyup="searchKeyUp()"
      ></v-text-field>
      <v-btn
        class="ml-4"
        depressed
        color="primary"
        @click="newClick"
      >
    <v-icon
      small
      left
    >fas fa-folder-plus</v-icon>
    Nieuw
    </v-btn>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >

      <template v-slot:item.action="{ item }">
        <v-icon
          small
          @click.stop="$refs.confirmDelete.show(item.id)"
        >fas fa-trash</v-icon>
      </template>

    </v-data-table>

    <ConfirmDialog
      ref="confirmDelete"
      title="Verwijderen"
      labelConfirm="Verwijderen"
      labelConfirmColor="error"
      @confirm="deleteClick"
    ></ConfirmDialog>


    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Artikelgroep</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>
            <v-text-field
              outlined
              class="mb-2"
              v-model="model.name"
              :error-messages="errors.name"
              label="Omschrijving"
            ></v-text-field>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Opslaan</v-btn>
        </v-card-actions>


      </v-card>





    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import ConfirmDialog from '@/components/ConfirmDialog'

export default {
  components: {
    ConfirmDialog,
  },
  setup() {
    const api = inject('api')
    const loader = inject('loader')

    const productGroupCollection = api.resource('product-groups').collection({
      rules: {
        query: 'query:name',
      }
    })

    const { items, filter, totalCount, options, isSearching } = productGroupCollection

    const headers = [
      { text: 'Name', value: 'name' },
      { text: '', value: 'action', align: 'center' },
    ]

    const dialog = ref(false)

    const productGroupModel = api.resource('product-groups').model()
    const { attributes: model, errors, isBusy } = productGroupModel

    loader.bindRef(isBusy)

    function newClick() {
      productGroupModel.clear()
      dialog.value = true
    }

    function rowClick(item) {
      productGroupModel.find(item.id).then(() => {
        dialog.value = true
      })
    }

    function saveClick() {
      productGroupModel.save().then(() => {
        productGroupCollection.search()
        dialog.value = false
      })
    }

    function deleteClick(id) {
      api.resource('product-groups').remove(id).then(() => {
        productGroupCollection.search()
      })
    }

    const searchKeyUp = debounce(productGroupCollection.search, 300)

    return {
      api,
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      isSearching,
      newClick,
      rowClick,
      saveClick,
      deleteClick,
      dialog,
      model,
      errors,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

</style>
