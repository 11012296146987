<template>
  <v-form 
    @submit.prevent>
    <v-row
      class="mt-0"
    >
      <v-col
        cols="12"
        md="8"
        lg="9"
      >
        <v-card
          flat
          class="mx-3 px-6 pb-16 mb-6"
        >
       
          <v-card-title class="primary--text px-0">Algemeen</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >


            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <number-field
                v-model="offerLine.quantity"
                outlined
                label="Aantal"
                class="centered-input"
                @change="updateField('quantity')"
                :disabled="!isEditable"
              >
              </number-field>
            </v-col>

          </v-row>

          <v-row
          >
            <v-col
              cols="12"
            >
              <v-text-field
                outlined
                v-model="offerLine.description"
                :error-messages="offerLineErrors.description"
                label="Omschrijving"
                @change="updateField('description')"
                :disabled="!isEditable"
              ></v-text-field>
            </v-col>


            <v-col
              cols="6"
            >
              <button-field
                v-model="offerLine.is_stock_item"
                :items="[{ value: 1, text: 'JA' }, { value: 0, text: 'NEE' }]"
                label="Inkoopartikel"
                @change="updateField('is_stock_item')"
                :disabled="!isActive || !isBeforeStatusOrdered"
              ></button-field>

            </v-col>

          </v-row>


          <v-card-title class="primary--text px-0">Opties</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >
            <v-col
              cols="12"
              sm="6"
            >
              <button-field
                v-model="offerLineProduct.options_special_assembly"
                :items="[{ value: 1, text: 'JA' }, { value: 0, text: 'NEE' }]"
                label="Speciale montage / gereedschap"
                @change="updateField('options_special_assembly')"
                :disabled="!isEditable"
              ></button-field>
            </v-col>

          </v-row>
          <v-row>

            <v-col
              cols="12"
              lg="6"
            >
              <v-text-field
                outlined
                v-model="offerLineProduct.options_description"
                :error-messages="offerLineProductErrors.options_description"
                label="Optieomschrijving"
                @change="updateField('options_description')"
                :disabled="!isEditable"
              ></v-text-field>
            </v-col>

          </v-row>
          <v-row>

            <v-col
              cols="12"
              lg="6"
            >
              <v-textarea
                outlined
                v-model="offerLineProduct.options_remarks"
                :error-messages="offerLineProductErrors.options_remarks"
                label="Overige opmerkingen"
                @change="updateField('options_remarks')"
                :disabled="!isEditable"
              ></v-textarea>
            </v-col>

          </v-row>

          <v-card-title class="primary--text px-0">Overzicht</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
            >

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLine.unit_price"
                :error-messages="offerLineErrors.unit_price"
                decimals="2"
                label="Prijs"
                prefix="€"
                @change="updateField('unit_price')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>
          </v-row>
          <v-row>

            <v-col
              cols="12"
              sm="6"
            >
              <date-picker
                outlined
                v-model="offerLine.delivery_date"
                :error-messages="offerLineErrors.delivery_date"
                label="Leverweek"
                @change="updateField('delivery_date')"
                :suffix="'week ' + formatDate(offerLine.delivery_date, 'W')"
                :disabled="!isActive"
              ></date-picker>

            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-checkbox
                v-model="offerLine.is_long_delivery_time"
                :error-messages="offerLineErrors.is_long_delivery_time"
                label="Lange levertijd"
                :disabled="!isActive"
              ></v-checkbox>

            </v-col>

          </v-row>


        </v-card>

      </v-col>
      <v-col
        cols="12"
        md="4"
        lg="3"
        class="pl-6 pl-md-3"
      >

        <v-banner
          sticky
          class="sticky-card"
        >

        <v-card
          flat
          class="pb-6"
        >
          <v-toolbar
            dark
            class="primary"
            dense
            elevation="0"
          >
            <v-toolbar-title>{{ offerLineProduct.window_description }}</v-toolbar-title>
          </v-toolbar>

          <dl class="row my-6 px-6 def-list">

            <dt class="col-6 py-3 primary--text">Prijs:</dt>
            <dd class="col-6 py-3 primary--text">{{ formatCurrency(price) }}</dd>

          </dl>

        </v-card>

        <v-btn
          block
          depressed
          color="primary"
          class="mt-6"
          large
          @click="saveClick"
          v-if="isActive"
        >Opslaan</v-btn>

        <v-btn
          block
          outlined
          color="primary"
          class="mt-6 white"
          large
          @click="backClick"
        >Terug</v-btn>

        </v-banner>

      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { inject } from '@vue/composition-api'
import NumberField from '@/components/NumberField'
import ButtonField from '@/components/ButtonField'
import DatePicker from '@/components/DatePicker'
import { convertItems, useOffer, useOfferLine, formatCurrency, formatDate } from '@/utils'


export default {
  components: {
    NumberField,
    ButtonField,
    DatePicker,
  },
  setup(props, context) {

    const api = inject('api')

    const offerModel = inject('offerModel')
    const offerLineTypeModel = inject('offerLineTypeModel')
    const offerLineModel = inject('offerLineModel')
    const offerLineProductModel = inject('offerLineProductModel')
    const suppliers = inject('suppliers')

    const { attributes: offerLineType } = offerLineTypeModel
    const { attributes: offerLine, errors: offerLineErrors, oldAttributes: oldOfferLine } = offerLineModel
    const { attributes: offerLineProduct, errors: offerLineProductErrors, oldAttributes: oldOfferLineProduct } = offerLineProductModel

    const { isEditable, isActive } = useOffer(offerModel)
    const { price, isMountingCoveMoldingInTheDay, isBeforeStatusOrdered } = useOfferLine(offerLineModel, offerLineProductModel)

    // systems
    const tierProducts = api.resource('tier-products').collection().all({
      filter: { tier_product_type_id: { eq: 3 }, },
    })


    function updateField(field) {
      console.log('update', field, offerLineProductModel.getAttribute(field))


    }


    function saveClick() {
      offerLineModel.save().then(({ data: offerLine }) => {
        offerLineProductModel.populate({ offer_line_id: offerLine.id })
        offerLineProductModel.save().then(() => {
          backClick()
        })
      })
    }

    function backClick() {
      context.root.$router.push({
        name: 'offer',
        params: { id: offerLineModel.getAttribute('offer_id') },
      })
    }



    return {
      isEditable,
      isActive,
      offerLineType,

      offerLine,
      offerLineErrors,
      oldOfferLine,
      offerLineProduct,
      offerLineProductErrors,
      oldOfferLineProduct,
      convertItems,
      formatCurrency,
      formatDate,
      suppliers,
      saveClick,
      backClick,
      price,
      tierProducts,
      isMountingCoveMoldingInTheDay,
      isBeforeStatusOrdered,
      updateField,
    }
  },
}
</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}

::v-deep .sticky-card {
  top: 140px !important;
}

::v-deep .sticky-card > .v-banner__wrapper {
  padding: 0 !important;
}

dl.def-list dt {
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}

dl.def-list dd {
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}


</style>