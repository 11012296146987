<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Gebruikers
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Zoek..."
        append-icon="fas fa-search"
        single-line
        hide-details
        @keyup="searchKeyUp()"
      ></v-text-field>
      <v-btn
        class="ml-4"
        depressed
        color="primary"
        @click="newClick"
      >
    <v-icon
      small
      left
    >fas fa-folder-plus</v-icon>
    Nieuw
    </v-btn>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >
    
      <template v-slot:item.roles="{ item }">
        {{ item.roles.map(roleName => roles.find(role => role.name === roleName).description).join(', ') }}
      </template>
    
      <template v-slot:item.status="{ item }">
        <v-icon :color="item.status == 10 ? 'green' : 'grey'" x-small>fas fa-circle</v-icon>
      </template>
    
    </v-data-table>


    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Gebruiker</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>
            <v-text-field
              outlined
              class="mb-2"
              v-model="model.username"
              :error-messages="errors.username"
              label="Gebruikersnaam"
            ></v-text-field>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.email"
              :error-messages="errors.email"
              label="Email"
            ></v-text-field>

            <v-text-field
              outlined
              class="mb-2"
              v-model="model.first_name"
              :error-messages="errors.first_name"
              label="Voornaam"
            ></v-text-field>

            <v-text-field
              outlined
              class="mb-2"
              type="password"
              v-model="model.password"
              :error-messages="errors.password"
              label="Nieuw wachtwoord"
            ></v-text-field>

            <v-checkbox
              v-model="model.status"
              :error-messages="errors.status"
              label="Actief"
              :false-value="9"
              :true-value="10"
            ></v-checkbox>


            <v-banner
              single-line
            >Rollen</v-banner>

            <v-checkbox
              v-for="role in roles"
              :key="role.name"
              v-model="model.roles"
              :label="role.description"
              :value="role.name"
            ></v-checkbox>

            <v-input
              :error-messages="errors.roles"
            ></v-input>



          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Opslaan</v-btn>
        </v-card-actions>


      </v-card>





    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import { debounce } from 'lodash'

export default {
  setup() {
    const api = inject('api')
    const loader = inject('loader')

    const roles = api.resource('users/roles').collection().all()

    const userCollection = api.resource('users').collection({
      rules: {
        query: 'query',
      }
    })

    const { items, filter, totalCount, options, isSearching } = userCollection

    const headers = [
      { text: 'Gebruikersnaam', value: 'username' },
      { text: 'Voornaam', value: 'first_name' },
      { text: 'Email', value: 'email' },
      { text: 'Rol', value: 'roles' },
      { text: 'Status', value: 'status', align: 'center' },
    ]

    const dialog = ref(false)

    const userModel = api.resource('users').model()
    const { attributes: model, errors, isBusy } = userModel

    loader.bindRef(isBusy)

    function newClick() {
      userModel.clear()
      dialog.value = true
    }

    function rowClick(item) {
      userModel.find(item.id).then(() => {
        dialog.value = true
      })
    }

    function saveClick() {
      userModel.save().then(() => {
        userCollection.search()
        dialog.value = false
      })
    }

    const searchKeyUp = debounce(userCollection.search, 300)

    return {
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      isSearching,
      newClick,
      rowClick,
      saveClick,
      dialog,
      model,
      errors,
      roles,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

</style>
