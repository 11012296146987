<template>
  <v-form 
    @submit.prevent>
    <v-row
      class="mt-0"
    >
      <v-col
        cols="12"
        md="8"
        lg="9"
      >
        <v-card
          flat
          class="mx-3 px-6 pb-16 mb-6"
        >
       
          <v-card-title class="primary--text px-0">Algemeen</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >
            <v-col
              cols="6"
              lg="4"
            >
              <images-field
                v-model="offerLineProduct.photo_url"
                label="Foto"
                :disabled="!isEditable"
              ></images-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              cols="12"
              md="8"
              lg="6"

            >
              <button-field
                v-model="offerLineProduct.curtain_type"
                :items="convertItems(offerLineType.curtain_types)"
                label="Gordijntype"
                @change="updateField('curtain_type')"
                :disabled="!isEditable"
              ></button-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-text-field
                v-model="offerLine.quantity"
                outlined
                label="Aantal"
                class="centered-input"
                readonly
                @change="updateField('quantity')"
                :disabled="!isEditable"
              >
                <template v-slot:prepend-inner>
                  <v-input hide-details>
                    <v-btn
                      small
                      icon
                      @click="offerLine.quantity > 0 ? offerLine.quantity-- : offerLine.quantity"
                      :disabled="!isEditable"
                    ><v-icon small>fas fa-minus</v-icon></v-btn>
                  </v-input>
                </template>
              
                <template v-slot:append>
                  <v-input hide-details>
                    <v-btn
                      small
                      icon
                      @click="offerLine.quantity++"
                      :disabled="!isEditable"
                    ><v-icon small>fas fa-plus</v-icon></v-btn>
                  </v-input>
                </template>
              
              </v-text-field>
            </v-col>

          </v-row>

          <v-row
          >
            <v-col
              cols="6"
            >
              <v-text-field
                outlined
                v-model="offerLineProduct.window_description"
                :error-messages="offerLineProductErrors.window_description"
                label="Raamomschrijving"
                @change="updateField('window_description')"
                :disabled="!isEditable"
              ></v-text-field>
            </v-col>

            <v-col
              cols="6"
            >
              <v-text-field
                outlined
                v-model="offerLineProduct.product_features"
                :error-messages="offerLineProductErrors.product_features"
                label="Bijzonderheden"
                @change="updateField('product_features')"
                :disabled="!isEditable"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card-title class="primary--text px-0">Stofsoort</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >
            <v-col
              cols="12"
              sm="6"
              lg="4"
            >
              <v-text-field
                outlined
                v-model="offerLineProduct.fabric_name"
                :error-messages="offerLineProductErrors.fabric_name"
                label="Stofnaam en kleurcode"
                @change="updateField('fabric_name')"
                :disabled="!isEditable"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="4"
            >
              <v-select
                outlined
                clearable
                v-model="offerLineProduct.fabric_type"
                :error-messages="offerLineProductErrors.fabric_type"
                :items="convertItems(offerLineType.fabric_types)"
                label="Stoftype"
                @change="updateField('fabric_type')"
                :disabled="!isEditable"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="4"
            >
              <number-field
                outlined
                v-model="offerLineProduct.fabric_length"
                :error-messages="offerLineProductErrors.fabric_length"
                integer
                :label="offerLineProduct.fabric_type"
                suffix="mm"
                @change="updateField('fabric_length')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="4"
            >
              <v-autocomplete
                outlined
                clearable
                v-model="offerLine.supplier_id"
                :error-messages="offerLineErrors.supplier_id"
                :items="suppliers"
                item-value="id"
                item-text="company_name"
                label="Leverancier"
                @change="updateField('supplier_id')"
                :disabled="!isEditable"
              ></v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="4"
            >
              <number-field
                outlined
                v-model="offerLineProduct.fabric_price"
                :error-messages="offerLineProductErrors.fabric_price"
                label="Stofprijs"
                decimals="2"
                prefix="€"
                suffix="p.m."
                @change="updateField('fabric_price')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
              lg="4"
            >
              <number-field
                outlined
                v-model="offerLineProduct.fabric_rapport_height"
                :error-messages="offerLineProductErrors.fabric_rapport_height"
                integer
                label="Rapporthoogte"
                suffix="mm"
                @change="updateField('fabric_rapport_height')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

          </v-row>

          <v-card-title class="primary--text px-0">Maatvoering</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >
            <v-col
              cols="12"
              sm="6"
            >
              <button-field
                v-model="offerLineProduct.dimensions_floor"
                :items="[{ value: 1, text: 'Aanwezig' }, { value: 0, text: 'Afwezig' }]"
                label="Vloer"
                @change="updateField('dimensions_floor')"
                :disabled="!isEditable"
              ></button-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_floor_height"
                :error-messages="offerLineProductErrors.dimensions_floor_height"
                integer
                label="Dikte vloer"
                suffix="mm"
                :disabled="!isEditable || !!offerLineProduct.dimensions_floor"
                @change="updateField('dimensions_floor_height')"
              ></number-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_width"
                :error-messages="offerLineProductErrors.dimensions_width"
                integer
                label="Breedte"
                suffix="mm"
                @change="updateField('dimensions_width')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="6"
              sm="3"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_overhang_left"
                :error-messages="offerLineProductErrors.dimensions_overhang_left"
                integer
                persistent-placeholder
                label="Overstek links"
                suffix="mm"
                @change="updateField('dimensions_overhang_left')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>
            <v-col
              cols="6"
              sm="3"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_overhang_right"
                :error-messages="offerLineProductErrors.dimensions_overhang_right"
                integer
                persistent-placeholder
                label="Overstek rechts"
                suffix="mm"
                @change="updateField('dimensions_overhang_right')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="6"
              sm="3"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_bend_left"
                :error-messages="offerLineProductErrors.dimensions_bend_left"
                integer
                persistent-placeholder
                label="Bocht links"
                suffix="mm"
                @change="updateField('dimensions_bend_left')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>
            <v-col
              cols="6"
              sm="3"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_bend_right"
                :error-messages="offerLineProductErrors.dimensions_bend_right"
                integer
                persistent-placeholder
                label="Bocht rechts"
                suffix="mm"
                @change="updateField('dimensions_bend_right')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="6"
              sm="3"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_total_width"
                :error-messages="offerLineProductErrors.dimensions_total_width"
                integer
                persistent-placeholder
                label="Totale breedte"
                suffix="mm"
                readonly
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="6"
              sm="3"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_clear_width"
                :error-messages="offerLineProductErrors.dimensions_clear_width"
                integer
                persistent-placeholder
                label="Klare breedte"
                suffix="mm"
                @change="updateField('dimensions_clear_width')"
                :disabled="!isEditable"
                :background-color="offerLineProduct.dimensions_clear_width_measured ? 'green' : 'red'"
              >
                <template v-slot:prepend>
                  <simple-checkbox v-model="offerLineProduct.dimensions_clear_width_measured" :true-value="1" :false-value="0" ></simple-checkbox>
                </template>
              </number-field>
            </v-col>

            <v-col
              cols="6"
              sm="3"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_min_height"
                :error-messages="offerLineProductErrors.dimensions_min_height"
                integer
                persistent-placeholder
                label="Minimale hoogte"
                suffix="mm"
                @change="updateField('dimensions_min_height')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="6"
              sm="3"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_max_height"
                :error-messages="offerLineProductErrors.dimensions_max_height"
                integer
                persistent-placeholder
                label="Maximale hoogte"
                suffix="mm"
                @change="updateField('dimensions_max_height')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="6"
              sm="3"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_total_height"
                :error-messages="offerLineProductErrors.dimensions_total_height"
                integer
                persistent-placeholder
                label="Totale hoogte"
                suffix="mm"
                readonly
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="6"
              sm="3"
            >
              <number-field
                outlined
                v-model="offerLineProduct.dimensions_clear_height"
                :error-messages="offerLineProductErrors.dimensions_clear_height"
                integer
                persistent-placeholder
                label="Klare hoogte"
                suffix="mm"
                @change="updateField('dimensions_clear_height')"
                :disabled="!isEditable"
                :background-color="offerLineProduct.dimensions_clear_height_measured ? 'green' : 'red'"
              >
                <template v-slot:prepend>
                  <simple-checkbox v-model="offerLineProduct.dimensions_clear_height_measured" :true-value="1" :false-value="0" ></simple-checkbox>
                </template>
              </number-field>
            </v-col>

          </v-row>



          <v-card-title class="primary--text px-0">Plooi en zoom</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >
            <v-col
              cols="12"
              sm="4"
            >
              <v-select
                outlined
                clearable
                v-model="offerLineProduct.distribution_type"
                :error-messages="offerLineProductErrors.distribution_type"
                :items="convertItems(offerLineType.distribution_types)"
                label="Verdeling"
                @change="updateField('distribution_type')"
                :disabled="!isEditable"
              ></v-select>
            </v-col>
            <v-col
              v-if="!isDistributionOther"
              cols="12"
              sm="4"
            >
              <v-text-field
                outlined
                v-model="offerLineProduct.distribution_left"
                :error-messages="offerLineProductErrors.distribution_left"
                label="Links"
                suffix="mm"
                :disabled="!isEditable || !(isDistributionUnequal || isDistributionLeft)"
                @change="updateField('distribution_left')"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="!isDistributionOther"
              cols="12"
              sm="4"
            >
              <v-text-field
                outlined
                v-model="offerLineProduct.distribution_right"
                :error-messages="offerLineProductErrors.distribution_right"
                label="Rechts"
                suffix="mm"
                :disabled="!isEditable || !(isDistributionUnequal || isDistributionRight)"
                @change="updateField('distribution_right')"
              ></v-text-field>
            </v-col>
            <v-col
              v-if="isDistributionOther"
              cols="12"
              sm="8"
            >
              <v-text-field
                outlined
                v-model="offerLineProduct.distribution_remarks"
                :error-messages="offerLineProductErrors.distribution_remarks"
                label="Opmerking"
                @change="updateField('distribution_remarks')"
                :disabled="!isEditable"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                outlined
                clearable
                v-model="offerLineProduct.pleat_type"
                :error-messages="offerLineProductErrors.pleat_type"
                :items="convertItems(offerLineType.pleat_types)"
                label="Plooi"
                @change="updateField('pleat_type')"
                :disabled="!isEditable"
              ></v-select>
            </v-col>

            <v-col
              v-if="isPleatTypeWave"
              cols="6"
              sm="3"
            >
              <v-select
                outlined
                clearable
                v-model="offerLineProduct.pleat_type_wave_option"
                :error-messages="offerLineProductErrors.pleat_type_wave_option"
                :items="convertItems(offerLineType.pleat_type_wave_options)"
                label="Wave optie"
                @change="updateField('pleat_type_wave_option')"
                :disabled="!isEditable"
              ></v-select>
            </v-col>

            <v-col
              :cols="isPleatTypeWave ? 6 : 12"
              :sm="isPleatTypeWave ? 3 : 6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.pleat_factor"
                :error-messages="offerLineProductErrors.pleat_factor"
                integer
                label="Plooifactor"
                suffix="%"
                @change="updateField('pleat_factor')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.pleat_deduction"
                :error-messages="offerLineProductErrors.pleat_deduction"
                integer
                label="Plooi aftrek"
                suffix="mm"
                @change="updateField('pleat_deduction')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.pleat_head"
                :error-messages="offerLineProductErrors.pleat_head"
                integer
                label="Hoofdje"
                suffix="mm"
                @change="updateField('pleat_head')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.curtain_hem"
                :error-messages="offerLineProductErrors.curtain_hem"
                integer
                label="Zoommaat / afwerking"
                suffix="mm"
                :disabled="!isEditable || !!offerLineProduct.curtain_lead_lace"
                @change="updateField('curtain_hem')"
              ></number-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <button-field
                v-model="offerLineProduct.curtain_lead_lace"
                :items="[{ value: 1, text: 'JA' }, { value: 0, text: 'NEE' }]"
                label="Loodveter"
                @change="updateField('curtain_lead_lace')"
                :disabled="!isEditable"
              ></button-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-combobox
                outlined
                clearable
                v-model="offerLineProduct.curtain_ring"
                :error-messages="offerLineProductErrors.curtain_ring"
                :items="convertItems(offerLineType.curtain_ring_options)"
                label="Ring"
                suffix="mm"
                :return-object="false"
                @change="updateField('curtain_ring')"
                :disabled="!isEditable"
              ></v-combobox>
            </v-col>
          </v-row>

          <v-card-title class="primary--text px-0">Montage</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >
            <v-col 
              cols="6"
              sm="3"
            >
              <v-select
                outlined
                clearable
                v-model="offerLineProduct.mounting_type"
                :error-messages="offerLineProductErrors.mounting_type"
                :items="convertItems(offerLineType.mounting_types)"
                label="Montage"
                @change="updateField('mounting_type')"
                :disabled="!isEditable"
              ></v-select>
            </v-col>

            <v-col 
              cols="6"
              sm="3"
            >
              <v-select
                outlined
                clearable
                v-model="offerLineProduct.mounting_material"
                :error-messages="offerLineProductErrors.mounting_material"
                :items="convertItems(offerLineType.mounting_materials)"
                label="Materiaal"
                @change="updateField('mounting_material')"
                :disabled="!isEditable"
              ></v-select>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <button-field
                v-model="offerLineProduct.mounting_suspension"
                :items="[{ value: 'rail', text: 'Rail' }, { value: 'roede', text: 'Roede' }]"
                label="Ophanging"
                @change="updateField('mounting_suspension')"
                :disabled="!isEditable"
              ></button-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <button-field
                v-model="offerLineProduct.mounting_fixation"
                :items="[{ value: 'plafond', text: 'Plafond' }, { value: 'wand', text: 'Wand' }]"
                label="Bevestiging"
                @change="updateField('mounting_fixation')"
                :disabled="!isEditable"
              ></button-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.mounting_ceiling_height"
                :error-messages="offerLineProductErrors.mounting_ceiling_height"
                integer
                label="Montagehoogte"
                suffix="mm"
                @change="updateField('mounting_ceiling_height')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.mounting_brackets"
                :error-messages="offerLineProductErrors.mounting_brackets"
                integer
                label="Aantal steunen"
                suffix="stuks"
                @change="updateField('mounting_brackets')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-select
                outlined
                clearable
                v-model="offerLineProduct.mounting_bending"
                :error-messages="offerLineProductErrors.mounting_bending"
                :items="convertItems(offerLineType.bending_types, true)"
                label="Buigen"
                @change="updateField('mounting_bending')"
                :disabled="!isEditable"
              >
                <template v-slot:selection="{ item }">
                  <img :src="item.text">
                </template>

                <template v-slot:item="{ item }">
                  <img :src="item.text">
                </template>
             
              </v-select>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.mounting_support_length"
                :error-messages="offerLineProductErrors.mounting_support_length"
                integer
                label="Steunlengte"
                suffix="mm"
                :disabled="!isEditable || !isMountingOnTheDay"
                @change="updateField('mounting_support_length')"
              ></number-field>
            </v-col>

            <v-col
              cols="3"
              sm="1"
            >
              <v-btn
                outlined
                block
                x-large
                color="primary"
                @click="saveClick(true)"
                :disabled="!isEditable"
              >Rails bijwerken</v-btn>
            </v-col>

          </v-row>

          <v-card-title class="primary--text px-0">Opties</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
          >
            <v-col
              cols="12"
              sm="6"
            >
              <button-field
                v-model="offerLineProduct.options_special_assembly"
                :items="[{ value: 1, text: 'JA' }, { value: 0, text: 'NEE' }]"
                label="Speciale montage / gereedschap"
                @change="updateField('options_special_assembly')"
                :disabled="!isEditable"
              ></button-field>
            </v-col>

          </v-row>
          <v-row>

            <v-col
              cols="12"
              lg="6"
            >
              <v-text-field
                outlined
                v-model="offerLineProduct.options_description"
                :error-messages="offerLineProductErrors.options_description"
                label="Optieomschrijving"
                @change="updateField('options_description')"
                :disabled="!isEditable"
              ></v-text-field>
            </v-col>

          </v-row>
          <v-row>

            <v-col
              cols="12"
              lg="6"
            >
              <v-textarea
                outlined
                v-model="offerLineProduct.options_remarks"
                :error-messages="offerLineProductErrors.options_remarks"
                label="Overige opmerkingen"
                @change="updateField('options_remarks')"
                :disabled="!isActive"
              ></v-textarea>
            </v-col>

          </v-row>


          <v-card-title class="primary--text px-0">Overzicht</v-card-title>
          <v-divider class="primary"></v-divider>

          <v-row
            class="mt-6"
            >

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLineProduct.additional_price"
                :error-messages="offerLineProductErrors.additional_price"
                label="Meerprijs"
                prefix="€"
                decimals="2"
                @change="updateField('additional_price')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <number-field
                outlined
                v-model="offerLine.unit_price"
                :error-messages="offerLineErrors.unit_price"
                label="Prijs"
                prefix="€"
                decimals="2"
                @change="updateField('unit_price')"
                :disabled="!isEditable"
              ></number-field>
            </v-col>
          </v-row>
          <v-row>

            <v-col
              cols="12"
              sm="6"
            >
              <date-picker
                outlined
                v-model="offerLine.delivery_date"
                :error-messages="offerLineErrors.delivery_date"
                label="Leverweek"
                @change="updateField('delivery_date')"
                :suffix="'week ' + formatDate(offerLine.delivery_date, 'W')"
                :disabled="!isActive"
              ></date-picker>

            </v-col>

            <v-col
              cols="12"
              sm="6"
            >
              <v-checkbox
                v-model="offerLine.is_long_delivery_time"
                :error-messages="offerLineErrors.is_long_delivery_time"
                label="Lange levertijd"
                :disabled="!isActive"
              ></v-checkbox>

            </v-col>

          </v-row>


        </v-card>

      </v-col>
      <v-col
        cols="12"
        md="4"
        lg="3"
        class="pl-6 pl-md-3"
      >

        <v-banner
          sticky
          class="sticky-card"
        >

        <v-card
          flat
          class="pb-6"
        >
          <v-toolbar
            dark
            class="primary"
            dense
            elevation="0"
          >
            <v-toolbar-title>{{ offerLineProduct.curtain_type || offerLineType.name }}</v-toolbar-title>
          </v-toolbar>

          <dl class="row my-6 px-6 def-list">
            <dt class="col-6">Leverancier:</dt>
            <dd class="col-6"><template v-if="supplier">{{ supplier.company_name }}</template></dd>
            <dt class="col-6">Stofnaam + kleur:</dt>
            <dd class="col-6">{{ offerLineProduct.fabric_name }}</dd>
            <dt class="col-6 pt-3 primary--text">Klare breedte:</dt>
            <dd class="col-6 pt-3"><template v-if="offerLineProduct.dimensions_clear_width">
                <span :style="{ color: offerLineProduct.dimensions_clear_width_measured ? 'green' : 'red' }">{{ offerLineProduct.dimensions_clear_width }} mm</span>
              </template></dd>
            <dt class="col-6 pb-3 primary--text">Klare hoogte:</dt>
            <dd class="col-6 pb-3 primary--text"><template v-if="offerLineProduct.dimensions_clear_height">
                <span :style="{ color: offerLineProduct.dimensions_clear_height_measured ? 'green' : 'red' }">{{ offerLineProduct.dimensions_clear_height }} mm</span>
              </template></dd>

            <dt class="col-6">Verdeling:</dt>
            <dd class="col-6">{{ offerLineProduct.distribution_type }}</dd>
            <dt class="col-6">Plooikeuze:</dt>
            <dd class="col-6">
              {{ offerLineProduct.pleat_type }}
              <span v-if="offerLineProduct.pleat_type_wave_option"> / {{ offerLineProduct.pleat_type_wave_option }}</span>
            </dd>
            <dt class="col-6">
              <template v-if="offerLineProduct.curtain_lead_lace">Loodveter:</template>
              <template v-if="!offerLineProduct.curtain_lead_lace && offerLineProduct.curtain_hem">Zoom:</template>
            </dt>
            <dd class="col-6">
              <template v-if="offerLineProduct.curtain_lead_lace">JA</template>
              <template v-if="!offerLineProduct.curtain_lead_lace && offerLineProduct.curtain_hem">JA</template>
            </dd>

            <template v-if="isFabricTypeWidth">
              <dt class="col-6 pt-3">Aantal banen:</dt>
              <dd class="col-6 pt-3"><template v-if="offerLineProduct.number_of_lanes">{{ offerLineProduct.number_of_lanes }} stuks</template></dd>
              <dt class="col-6">Hoogte banen:</dt>
              <dd class="col-6"><template v-if="offerLineProduct.fabric_height">{{ offerLineProduct.fabric_height }} mm</template></dd>
            </template>

            <template v-if="isFabricTypeHeight">
              <dt class="col-6 pt-3">Benodigde stof:</dt>
              <dd class="col-6 pt-3"><template v-if="offerLineProduct.fabric_height">{{ offerLineProduct.fabric_height }} mm</template></dd>
            </template>

            <dt class="col-6 pt-3">Bijzonderheden:</dt>
            <dd class="col-6 pt-3 error--text">{{ offerLineProduct.product_features }}</dd>

            <dt class="col-6 pt-3">Overige opmerkingen:</dt>
            <dd class="col-6 pt-3">{{ offerLineProduct.options_remarks }}</dd>

            <dt class="col-6 py-3 primary--text">Prijs:</dt>
            <dd class="col-6 py-3 primary--text">{{ formatCurrency(price) }}</dd>

          </dl>

        </v-card>

        <v-btn
          block
          depressed
          color="primary"
          class="mt-6"
          large
          @click="saveClick()"
          v-if="isActive"
        >Opslaan</v-btn>

        <v-btn
          block
          outlined
          color="primary"
          class="mt-6 white"
          large
          @click="backClick"
        >Terug</v-btn>

        </v-banner>

      </v-col>
    </v-row>
  </v-form>
</template>

<script>
/* eslint-disable */
import { inject, computed } from '@vue/composition-api'
import NumberField from '@/components/NumberField'
import ButtonField from '@/components/ButtonField'
import DatePicker from '@/components/DatePicker'
import ImagesField from '@/components/ImagesField.vue'
import SimpleCheckbox from '@/components/SimpleCheckbox'
import { convertItems, useOffer, useOfferLine, formatCurrency, formatDate } from '@/utils'


export default {
  components: {
    NumberField,
    ButtonField,
    DatePicker,
    ImagesField,
    SimpleCheckbox,
  },
  setup(props, context) {
 
    const offerModel = inject('offerModel')
    const offerLineTypeModel = inject('offerLineTypeModel')
    const offerLineModel = inject('offerLineModel')
    const offerLineProductModel = inject('offerLineProductModel')
    
    const suppliers = inject('suppliers')

    const { attributes: offerLineType } = offerLineTypeModel
    const { attributes: offerLine, errors: offerLineErrors, oldAttributes: oldOfferLine } = offerLineModel
    const { attributes: offerLineProduct, errors: offerLineProductErrors, oldAttributes: oldOfferLineProduct } = offerLineProductModel
    const { isEditable, isActive } = useOffer(offerModel)
    const { price, isDistributionUnequal, isDistributionOther, isDistributionLeft, isDistributionRight, isPleatTypeSingle, isPleatTypeWave, isMountingOnTheDay, isFabricTypeWidth, isFabricTypeHeight } = useOfferLine(offerLineModel, offerLineProductModel)

    const supplier = computed(() => suppliers.value ? suppliers.value.find(item => item.id == offerLineModel.getAttribute('supplier_id')) : {})

    function saveClick(checkRail = false) {
      offerLineModel.save().then(({ data: offerLine }) => {
        offerLineProductModel.populate({ offer_line_id: offerLine.id })
        offerLineProductModel.setAttribute('check_rail', checkRail)
        offerLineProductModel.save().then(() => {
          if (!checkRail) {
            backClick()
          }
        })
      })
    }

    function backClick() {
      context.root.$router.push({
        name: 'offer',
        params: { id: offerLineModel.getAttribute('offer_id') },
      })
    }

    function updateField(field) {

      console.log('update', field)

      // pleat
      if (['pleat_type', 'pleat_factors'].includes(field)) {
        let pleatType = offerLineProductModel.getAttribute('pleat_type')
        let pleatFactors = offerLineTypeModel.getAttribute('pleat_factors')
        let pleatHead = (pleatType === 'Enkel' || pleatType === 'Vlinder') ? 25 : null
        offerLineProductModel.setAttribute('pleat_factor', pleatFactors[pleatType] || null)
        updateField('pleat_factor')
        offerLineProductModel.setAttribute('pleat_head', pleatHead)
        updateField('pleat_head')
      }

      if (['pleat_type'].includes(field) && offerLineProductModel.isDirtyAttribute('pleat_type')) {
        offerLineProductModel.setAttribute('pleat_type_wave_option', null)
        updateField('pleat_type_wave_option')
      }

      // floor
      if (['dimensions_floor'].includes(field) && !offerLineProductModel.getAttribute('dimensions_floor')) {
        offerLineProductModel.setAttribute('dimensions_floor_height', null)
        updateField('dimensions_floor_height')
      }

      // distribution change
      if (['distribution_type'].includes(field) && offerLineProductModel.isDirtyAttribute('distribution_type')) {
        offerLineProductModel.setAttributes({ distribution_left: null, distribution_right: null, distribution_remarks: null })
        updateField('distribution_left')
        updateField('distribution_right')
        updateField('distribution_remarks')
      }

      // mounting
      if (['mounting_type'].includes(field) && offerLineProductModel.getAttribute('mounting_type') !== 'Op de dag') {
        offerLineProductModel.setAttribute('mounting_support_length', null)
        updateField('mounting_support_length')
      }

      // curtain_lead_lace
      if (['curtain_lead_lace'].includes(field) && offerLineProductModel.getAttribute('curtain_lead_lace')) {
        offerLineProductModel.setAttribute('curtain_hem', null)
        updateField('curtain_hem')
      }

      // width
      if (['dimensions_width', 'dimensions_overhang', 'dimensions_bend'].includes(field)) {
        let dimensionsWidth = Number(offerLineProductModel.getAttribute('dimensions_width', 0))
        let dimensionsOverhang = Number(offerLineProductModel.getAttribute('dimensions_overhang', 0))
        let dimensionsBend = Number(offerLineProductModel.getAttribute('dimensions_bend', 0))

        let totalWidth = Number((dimensionsWidth + dimensionsOverhang + dimensionsBend).toFixed(10))
        offerLineProductModel.setAttribute('dimensions_total_width', totalWidth)
        updateField('dimensions_total_width')
      }

      // rail
      if (['dimensions_total_width', 'mounting_suspension'].includes(field)) {
        let dimensionsTotalWidth = Number(offerLineProductModel.getAttribute('dimensions_total_width', 0))
        let railWidth = Math.floor(dimensionsTotalWidth / 1000)
        let railWidthRemainder = dimensionsTotalWidth % 1000

        if (railWidthRemainder > 0) {
          railWidth += (railWidthRemainder <= 500 ? 0.5 : 1)
        }

        if (offerLineProductModel.getAttribute('mounting_suspension') != 'rail') {
          railWidth = null
        }

        offerLineProductModel.setAttribute('rail_width', railWidth)
        updateField('rail_width')
      }

      // height
      if (['dimensions_min_height', 'dimensions_floor_height', 'pleat_deduction'].includes(field)) {
        let dimensionsMinHeight = Number(offerLineProductModel.getAttribute('dimensions_min_height', 0))
        let dimensionsFloorHeight = Number(offerLineProductModel.getAttribute('dimensions_floor_height', 0))
        let pleatDeduction = Number(offerLineProductModel.getAttribute('pleat_deduction', 0))

        let totalHeight = Number((dimensionsMinHeight - dimensionsFloorHeight - pleatDeduction).toFixed(10))
        offerLineProductModel.setAttribute('dimensions_total_height', totalHeight)
        updateField('dimensions_total_height')
      }

      if (['dimensions_overhang_left', 'dimensions_overhang_right'].includes(field)) {
        let dimensionsOverhang = Number(offerLineProductModel.getAttribute('dimensions_overhang_left', 0)) + Number(offerLineProductModel.getAttribute('dimensions_overhang_right', 0))
        offerLineProductModel.setAttribute('dimensions_overhang', dimensionsOverhang)
        updateField('dimensions_overhang')
      }

      if (['dimensions_bend_left', 'dimensions_bend_right'].includes(field)) {
        let dimensionsBend = Number(offerLineProductModel.getAttribute('dimensions_bend_left', 0)) + Number(offerLineProductModel.getAttribute('dimensions_bend_right', 0))
        offerLineProductModel.setAttribute('dimensions_bend', dimensionsBend)
        updateField('dimensions_bend')
      }

      // price
      if (['fabric_type', 'dimensions_clear_width', 'pleat_factor', 'fabric_price', 'fabric_length', 'fabric_rapport_height', 'dimensions_clear_height', 'curtain_hem', 'additional_price'].includes(field)) {
        let fabricType = offerLineProductModel.getAttribute('fabric_type')
        let dimensionsClearWidth = Number(offerLineProductModel.getAttribute('dimensions_clear_width', 0))
        let pleatFactor = Number(offerLineProductModel.getAttribute('pleat_factor', 0))
        let fabricPrice = Number(offerLineProductModel.getAttribute('fabric_price', 0))
        let fabricLength = Number(offerLineProductModel.getAttribute('fabric_length', 0))
        let additionalPrice = Number(offerLineProductModel.getAttribute('additional_price', 0))
        let unitPrice = null
        let numberOfLanes = null
        let fabricHeight = null

        if (fabricType === 'Breedte' && fabricLength) {
          let fabricRapportHeight = Number(offerLineProductModel.getAttribute('fabric_rapport_height', 0))
          let dimensionsClearHeight = Number(offerLineProductModel.getAttribute('dimensions_clear_height', 0))
          let curtainHem = Number(offerLineProductModel.getAttribute('curtain_hem', 0))
          
          fabricHeight = fabricRapportHeight ? Math.ceil((dimensionsClearHeight + curtainHem) / fabricRapportHeight) * fabricRapportHeight : dimensionsClearHeight + curtainHem
          numberOfLanes = Math.ceil(dimensionsClearWidth * pleatFactor / 100 / fabricLength)
          unitPrice = Number((numberOfLanes * fabricHeight / 1000 * fabricPrice).toFixed(10))
        } else if (fabricType === 'Hoogte') {
          unitPrice = Number((dimensionsClearWidth / 1000 * pleatFactor / 100 * fabricPrice).toFixed(10))
        }

        offerLineProductModel.setAttribute('number_of_lanes', numberOfLanes)
        updateField('number_of_lanes')

        offerLineProductModel.setAttribute('fabric_height', fabricHeight)
        updateField('fabric_height')

        offerLineModel.setAttribute('unit_price', unitPrice + additionalPrice)
        updateField('unit_price')
      }

      // description
      if (['window_description', 'product_features', 'curtain_type', 'fabric_name', 'distribution_type', 'distribution_remarks', 'pleat_type'].includes(field)) {

        let pleatType = offerLineProductModel.getAttribute('pleat_type');

        if (isPleatTypeSingle.value) {
          pleatType = 'Enkele'
        } else if (isPleatTypeWave.value) {
          pleatType = 'Wave'
        }

        // ruimte/product/bijzonderheden/stofnaam + kleur/verdeling/plooi.
        let description = (offerLineProductModel.getAttribute('window_description') ? offerLineProductModel.getAttribute('window_description') : '')
            + (offerLineProductModel.getAttribute('curtain_type') ? ', ' + offerLineProductModel.getAttribute('curtain_type').toLowerCase() : '')
            + (offerLineProductModel.getAttribute('product_features') ? ', ' + offerLineProductModel.getAttribute('product_features') : '')
            + (offerLineProductModel.getAttribute('fabric_name') ? ', ' + offerLineProductModel.getAttribute('fabric_name') : '')
            + (offerLineProductModel.getAttribute('distribution_type')
              ? ', ' + (isDistributionOther.value ? offerLineProductModel.getAttribute('distribution_remarks', '') : offerLineProductModel.getAttribute('distribution_type').toLowerCase()) : '')
            + (pleatType ? ', ' + pleatType.toLowerCase() + ' plooi' : '')
        
        offerLineModel.setAttribute('description', description)
        updateField('description')
      }

    }




    return {
      isEditable,
      isActive,

      offerLineType,
      
      offerLine,
      offerLineErrors,

      offerLineProduct,
      offerLineProductErrors,

      oldOfferLine,
      oldOfferLineProduct,

      convertItems,
      formatCurrency,
      formatDate,
      price,
      suppliers,
      supplier,

      isDistributionUnequal,
      isDistributionOther,
      isDistributionLeft,
      isDistributionRight,
      isPleatTypeWave,
      isMountingOnTheDay,
      isFabricTypeWidth,
      isFabricTypeHeight,

      updateField,

      saveClick,
      backClick,
    }
  },
}
</script>

<style scoped>
.centered-input >>> input {
  text-align: center
}

::v-deep .sticky-card {
  top: 140px !important;
}

::v-deep .sticky-card > .v-banner__wrapper {
  padding: 0 !important;
}

dl.def-list dt {
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}

dl.def-list dd {
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}


</style>