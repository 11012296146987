<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Klanten
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Zoek..."
        append-icon="fas fa-search"
        single-line
        hide-details
        clearable
        clear-icon="fa-times"
        @keyup="searchKeyUp()"
        @click:clear="searchKeyUp()"
      ></v-text-field>
      <v-btn
        v-if="!selected.length"
        class="ml-4"
        depressed
        color="primary"
        @click="newClick"
      >
        <v-icon
          small
          left
        >fas fa-folder-plus</v-icon>
        Nieuw
      </v-btn>
      <v-btn
        v-if="selected.length"
        class="ml-4"
        depressed
        color="primary"
        @click="$refs.confirmMerge.show(selected)"
      >
        <v-icon
          small
          left
        >fas fa-share-all</v-icon>
        Samenvoegen
      </v-btn>
    </v-row>

    <v-data-table
      v-model="selected"
      show-select
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
      @click:row="rowClick"
    >
    
      <template v-slot:item.action="{ item }">
        <v-icon
          small
          @click.stop="$refs.confirmDelete.show(item.id)"
        >fas fa-trash</v-icon>
      </template>

    </v-data-table>

    <ConfirmDialog
      ref="confirmDelete"
      title="Verwijderen"
      labelConfirm="Verwijderen"
      labelConfirmColor="error"
      @confirm="deleteClick"
    ></ConfirmDialog>

    <ConfirmDialog
      ref="confirmMerge"
      title="Samenvoegen"
      labelConfirm="Samenvoegen"
      labelConfirmColor="warning"
      @confirm="mergeClick"
    ></ConfirmDialog>

    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Klant</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="dialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <v-form @submit.prevent>

            <v-text-field
              outlined
              v-model="model.company_name"
              :error-messages="errors.company_name"
              label="Bedrijfsnaam"
            ></v-text-field>

            <v-text-field
              outlined
              v-model="model.contact_name"
              :error-messages="errors.contact_name"
              label="Contactpersoon"
            ></v-text-field>

            <v-text-field
              outlined
              v-model="model.address"
              :error-messages="errors.address"
              label="Adres (straat + huisnr)"
            ></v-text-field>

            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  v-model="model.postcode"
                  :error-messages="errors.postcode"
                  label="Postcode"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  outlined
                  v-model="model.city"
                  :error-messages="errors.city"
                  label="Woonplaats"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-text-field
              outlined
              v-model="model.phone_number"
              :error-messages="errors.phone_number"
              label="Telefoonnummer"
            ></v-text-field>

            <v-text-field
              outlined
              v-model="model.email"
              :error-messages="errors.email"
              label="E-mail"
            ></v-text-field>

            <v-textarea
              outlined
              v-model="model.remarks"
              :error-messages="errors.remarks"
              label="Notitie"
            ></v-textarea>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="dialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Opslaan</v-btn>
        </v-card-actions>


      </v-card>





    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import ConfirmDialog from '@/components/ConfirmDialog'
import { scrollToFirstError } from '@/utils'

export default {
  components: {
    ConfirmDialog,
  },
  setup(props, context) {
    const api = inject('api')
    const loader = inject('loader')

    const customerCollection = api.resource('customers').collection({
      rules: {
        query: 'query:contact_name,company_name,address,postcode,city,email,phone_number',
      },
      store: true,
    })

    const { items, filter, totalCount, options, isSearching } = customerCollection

    const headers = [
      { text: 'Klantnummer', value: 'id' },
      { text: 'Naam', value: 'name' },
      { text: 'E-mailadres', value: 'email' },
      { text: 'Adres', value: 'address' },
      { text: 'Woonplaats', value: 'city' },
      { text: 'Telefoonnummer', value: 'phone_number' },
      { text: '', value: 'action', align: 'center' },
    ]

    const dialog = ref(false)

    const customerModel = api.resource('customers').model()
    const { attributes: model, errors, isBusy } = customerModel

    loader.bindRef(isBusy)

    function newClick() {
      customerModel.clear()
      dialog.value = true
    }

    function rowClick(item) {
      context.root.$router.push({
        name: 'customer',
        params: { id: item.id }
      })

      // customerModel.find(item.id).then(() => {
      //   dialog.value = true
      // })
    }

    function saveClick() {
      customerModel.save().then(() => {
        customerCollection.search()
        dialog.value = false
      }).catch(() => {
        scrollToFirstError()
      })
    }

    function deleteClick(id) {
      api.resource('customers').remove(id).then(() => {
        customerCollection.search()
      })
    }

    function mergeClick() {
      api.resource('customers').post('merge', selected.value.map(item => item.id)).then(() => {
        selected.value = []
        customerCollection.search()
      })
    }

    const searchKeyUp = debounce(customerCollection.search, 300)

    const selected = ref([])

    return {
      selected,
      headers,
      items,
      filter,
      searchKeyUp,
      totalCount,
      options,
      isSearching,
      newClick,
      rowClick,
      saveClick,
      deleteClick,
      mergeClick,
      dialog,
      model,
      errors,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}

</style>
