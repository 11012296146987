<template>
  <v-card
    v-if="oldOffer.customer"
    flat
    v-bind="$attrs"
  >
    <v-toolbar
      dark
      class="primary"
      dense
      elevation="0"
    >
      <v-toolbar-title>{{ oldOffer.customer.name }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        :to="{ name: 'customer', params: { id: oldOffer.customer_id }}"
        icon
        @click="dialog = true"
      >
        <v-icon small>fas fa-link</v-icon>
      </v-btn>
      <v-btn
        :disabled="!isEditable"
        icon
        @click="dialog = true"
      >
        <v-icon small>fas fa-edit</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>

      <div>{{ oldOffer.customer.company_name }}</div>
      <div>{{ oldOffer.customer.contact_name }}</div>
      <div>{{ oldOffer.customer.address }}</div>
      <div>{{ oldOffer.customer.postcode }} {{ oldOffer.customer.city }}</div>
      <div>{{ oldOffer.customer.phone_number }}</div>
      <div>{{ oldOffer.customer.email }}</div>
      
      <h4 class="primary--text mt-3">Offertedatum:</h4>
      <div>{{ oldOffer.offer_date ? formatDate(oldOffer.offer_date) : '-' }}</div>

      <h4 class="primary--text mt-3">Verwachte leverdatum:</h4>
      <div>{{ oldOffer.delivery_date ? formatDate(oldOffer.delivery_date) : '-' }}</div>

      <h4 class="primary--text mt-3">Status:</h4>
      <div>{{ oldOffer.offer_status.name }}</div>
      <div v-if="oldOffer.offered_with_own_sizes" class="mt-1 font-italic">Uitgebracht met eigen maten!</div>

      <h4 class="primary--text mt-3">Opmerking / afspraak met klant:</h4>
      <div v-html="oldOffer.customer_remarks ? formatText(oldOffer.customer_remarks) : '-'"></div>

      <h4 class="primary--text mt-3">Opmerking tbv montage:</h4>
      <div v-html="oldOffer.assembly_remarks ? formatText(oldOffer.assembly_remarks) : '-'"></div>

      <h4 class="primary--text mt-3">Offreur:</h4>
      <div>{{ oldOffer.user ? oldOffer.user.first_name : '-' }}</div>

      <h4 class="primary--text mt-3">Geholpen door:</h4>
      <div>{{ oldOffer.served_user ? oldOffer.served_user.first_name : '-' }}</div>

      <h4 class="primary--text mt-3">Geholpen op:</h4>
      <div>{{ oldOffer.served_date ? formatDate(oldOffer.served_date) : '-' }}</div>

    </v-card-text>

    <v-dialog
      v-model="dialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Offerte</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="hideDialog()" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">

          <v-form @submit.prevent
            class="mt-6"
          >

            <date-picker
              v-model="offer.offer_date"
              :error-messages="errors.offer_date"
              outlined
              label="Offertedatum"
            ></date-picker>

            <date-picker
              v-model="offer.delivery_date"
              :error-messages="errors.delivery_date"
              outlined
              label="Verwachte leverdatum"
            ></date-picker>

            <v-select
              outlined
              v-model="offer.offer_status_id"
              :error-messages="errors.offer_status_id"
              :items="offerStatuses"
              item-text="name"
              item-value="id"
              label="Status"
            ></v-select>

            <v-checkbox
              class="mt-0 mb-4"
              v-model="offer.offered_with_own_sizes"
              label="Uitgebracht met eigen maten"
            ></v-checkbox>

            <v-select
              outlined
              v-model="offer.user_id"
              :error-messages="errors.user_id"
              :items="users"
              item-text="first_name"
              item-value="id"
              label="Offreur"
            ></v-select>

            <v-select
              outlined
              v-model="offer.served_user_id"
              :error-messages="errors.served_user_id"
              :items="users"
              item-text="first_name"
              item-value="id"
              label="Geholpen door"
            ></v-select>

            <date-picker
              v-model="offer.served_date"
              :error-messages="errors.served_date"
              outlined
              label="Geholpen op"
            ></date-picker>

            <v-select
              v-if="isRejected"
              outlined
              v-model="offer.offer_reject_reason_id"
              :error-messages="errors.offer_reject_reason_id"
              :items="offerRejectReasons"
              item-text="description"
              item-value="id"
              label="Afkeurreden"
            ></v-select>

            <v-textarea
              outlined
              v-model="offer.customer_remarks"
              :error-messages="errors.customer_remarks"
              label="Opmerking / afspraak met klant"
            ></v-textarea>

            <v-textarea
              outlined
              v-model="offer.assembly_remarks"
              :error-messages="errors.assembly_remarks"
              label="Opmerkingen tbv montage"
            ></v-textarea>

            <date-picker
              v-model="offer.reminder_date"
              :error-messages="errors.reminder_date"
              outlined
              label="Nabellen vanaf"
            ></date-picker>

          </v-form>
        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="hideDialog()"
          >Annuleren</v-btn>
          <v-btn
            :disabled="!isEditable"
            color="primary"
            class="px-5"
            large
            depressed
            @click="saveClick()"
          >Opslaan</v-btn>
        </v-card-actions>

      </v-card>

    </v-dialog>

  </v-card>

  
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import DatePicker from '@/components/DatePicker'
import { formatDate, formatText, useOffer } from '@/utils'


export default {
  components: {
    DatePicker,
  },
  setup() {
    const offerModel = inject('offerModel')
    const offerStatuses = inject('offerStatuses')
    const offerRejectReasons = inject('offerRejectReasons')
    const users = inject('users')

    const { attributes: offer, errors, oldAttributes: oldOffer } = offerModel
    const { isRejected, isEditable } = useOffer(offerModel)

    const dialog = ref(false)

    function saveClick() {
      offerModel.save().then(() => {
        dialog.value = false
      })
    }

    function hideDialog() {
      offerModel.reset()
      dialog.value = false
    }

    return {
      dialog,

      formatDate,
      formatText,

      offer,
      oldOffer,
      errors,

      isEditable,

      offerStatuses,
      offerRejectReasons,
      isRejected,

      users,

      saveClick,
      hideDialog,
    }
    
  },
}
</script>