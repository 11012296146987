<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Factureren
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Zoek..."
        append-icon="fas fa-search"
        single-line
        hide-details
        clearable
        clear-icon="fa-times"
        @keyup="searchKeyUp()"
        @click:clear="searchKeyUp()"
      ></v-text-field>
    </v-row>

    <v-card
      flat
      v-for="(offer, index) in offers"
      :key="'offer_'+index"
      class="mb-8"
    >

      <v-card-title>{{ offer.offer_number }} - {{ offer.customer.name }}</v-card-title>

      <v-data-table
        v-model="selected[offer.id]"
        show-select
        :headers="headers"
        :items="offer.offer_lines"
        :loading="isSearching"
        disable-pagination
        disable-sort
        disable-filtering
        hide-default-footer
        selectable-key="is_delivered"
      >

        <template v-slot:item.offer_line_status_id="{ item }">
          <v-chip
            small
            dark
            :color="getOfferLineStatusColor(item.offer_line_status_id)"
          >{{ item.offer_line_status.name }}</v-chip>
        </template>

        <template v-slot:item.assembly_date="{ item }">
          {{ formatDate(item.assembly_date) }}
        </template>

        <template v-slot:item.unit_price="{ item }">
          {{ formatCurrency(item.unit_price) }}
        </template>

        <template v-slot:item.price="{ item }">
          {{ formatCurrency(item.price) }}
        </template>

      </v-data-table>

      <v-btn
        color="primary"
        class="mt-6"
        depressed
        dense
        :disabled="!selected[offer.id].length"
        @click="billingClick(offer)"
      >
        <v-icon small left>fas fa-truck</v-icon>
        Factureren
      </v-btn>

    </v-card>


    <v-dialog
      v-model="billingDialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Factureren</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="billingDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">
          <p>{{ offerLines.length }} offerteregel(s) geselecteerd</p>

        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="billingDialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="billingSave()"
          >Factureren</v-btn>
        </v-card-actions>


      </v-card>

    </v-dialog>

  </v-card>
  
</template>

<script>
import Vue from 'vue'
import { inject, computed, ref, reactive } from '@vue/composition-api'
import { debounce } from 'lodash'
import { formatCurrency, formatDate, getOfferLineStatusColor, OFFER_STATUS_ORDERED, OFFER_LINE_STATUS_DELIVERED, OFFER_LINE_STATUS_BILLED } from '@/utils'

export default {
  components: {
  },
  setup() {
    const api = inject('api')

    const offerCollection = api.resource('offers').collection({
      params: {
        expand: 'offer_lines,customer,offer_lines.supplier,offer_lines.offer_line_status',
        filter: {
          offer_status_id: { eq: OFFER_STATUS_ORDERED },
          offer_line_status_id: { eq: OFFER_LINE_STATUS_DELIVERED },
        },
      },
      rules: {
        query: 'query:contact_name,company_name',
      },
    })

    offerCollection.all()

    const { filter, isSearching } = offerCollection
    const selected = reactive({})

    const offers = computed(() => {

      offerCollection.forEach(offer => {
        Vue.set(selected, offer.id, [])
      })

      return offerCollection.state.items
    })

    const headers = [
      { text: 'Status', value: 'offer_line_status_id', sortable: false },
      { text: 'Montage datum', value: 'assembly_date', sortable: false },
      { text: 'Omschrijving', value: 'description', sortable: false },
      { text: 'Leverancier', value: 'supplier.company_name', sortable: false },
      { text: 'Aantal', value: 'quantity', sortable: false },
      { text: 'Stuksprijs', value: 'unit_price', sortable: false },
      { text: 'Prijs', value: 'price', sortable: false },
    ]

    const billingDialog = ref(false)

    const offerModel = api.resource('offers').model()
    const offerLineCollection = api.resource('offer-lines').collection()

    const { attributes: offer } = offerModel
    const { items: offerLines } = offerLineCollection

    function billingClick(offer) {
      offerModel.populate(offer)
      offerLineCollection.populate(selected[offer.id])
      billingDialog.value = true
    }

    async function billingSave() {

      let promises = []

      offerLineCollection.forEach(offerLine => {
        let offerLineModel = api.resource('offer-lines').model()
        offerLineModel.populate(offerLine)
        offerLineModel.setAttributes({
          offer_line_status_id: OFFER_LINE_STATUS_BILLED,
        })
        promises.push(offerLineModel.save)
      })

      for (let promise of promises) {
        await promise()
      }

      await offerCollection.all()
      billingDialog.value = false
    }

    const searchKeyUp = debounce(offerCollection.search, 300)

    
    return {
      selected,
      headers,
      offers,
      filter,
      isSearching,
      billingDialog,
      billingClick,
      billingSave,
      formatCurrency,
      offer,
      offerLines,
      getOfferLineStatusColor,
      formatDate,
      searchKeyUp,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}


</style>
