<template>
  <v-card
    flat
    class="ma-3 pa-6"
  >
    <v-row align="center" class="pl-0 mb-6 pt-3 pr-3">
      <v-card-title>
        Binnenkomst goederen
      </v-card-title>
      <v-spacer></v-spacer>
      <v-text-field
        outlined
        dense
        v-model="filter.query"
        placeholder="Zoek..."
        append-icon="fas fa-search"
        single-line
        hide-details
        clearable
        clear-icon="fa-times"
        @keyup="searchKeyUp()"
        @click:clear="searchKeyUp()"
      ></v-text-field>
      <v-select
        outlined
        dense
        single-line
        hide-details
        clearable
        clear-icon="fa-times"
        class="ml-4"
        v-model="filter.supplier_id"
        :items="suppliers"
        item-value="id"
        item-text="company_name"
        @change="search()"
        @click:clear="search()"
        placeholder="Leverancier"
      ></v-select>
    </v-row>

    <v-data-table
      v-model="selected"
      show-select
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="totalCount"
      :footer-props="{ 'items-per-page-options': [5, 10, 50] }"
      :loading="isSearching"
    >

      <template v-slot:item.unit_price="{ item }">
        {{ formatCurrency(item.unit_price) }}
      </template>

      <template v-slot:item.price="{ item }">
        {{ formatCurrency(item.price) }}
      </template>

    </v-data-table>

    <v-btn
      color="primary"
      depressed
      :disabled="!selected.length"
      dense
      @click="arrivalDialog = true"
    >
      <v-icon small left>fas fa-truck</v-icon>
      Binnen melden
    </v-btn>

    <v-dialog
      v-model="arrivalDialog"
      max-width="700"
      persistent
    >
      <v-card>
        <v-toolbar color="title" dark flat short class="px-2">
          <v-toolbar-title>Binnen melden</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn @click="arrivalDialog = false" icon><v-icon>fas fa-times</v-icon></v-btn>
        </v-toolbar>

        <v-card-text class="mt-12">

          {{ selected.length }} offerteregel(s) geselecteerd

        </v-card-text>

        <v-card-actions class="px-6 pb-8">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-5"
            large
            outlined
            depressed
            @click="arrivalDialog = false"
          >Annuleren</v-btn>
          <v-btn
            color="primary"
            class="px-5"
            large
            depressed
            @click="arrivalSave()"
          >Inplannen</v-btn>
        </v-card-actions>


      </v-card>

    </v-dialog>

  </v-card>
  
</template>

<script>
import { inject, ref } from '@vue/composition-api'
import { debounce } from 'lodash'
import { formatCurrency, OFFER_STATUS_ORDERED, OFFER_LINE_STATUS_ORDERED, OFFER_LINE_STATUS_RECEIVED } from '@/utils'

export default {
  components: {
  },
  setup() {
    const api = inject('api')

    const offerLineCollection = api.resource('offer-lines').collection({
      params: {
        expand: 'offer,offer.customer,supplier,offer_line_status',
        filter: {
          offer_status_id: { eq: OFFER_STATUS_ORDERED },
          offer_line_status_id: { eq: OFFER_LINE_STATUS_ORDERED }
        },
      },
      rules: {
        query: 'query:contact_name,company_name,purchase_number,postcode,city,email,phone_number,offer_number',
      },
    })

    const suppliers = api.resource('suppliers').collection({
      params: {
        sort: 'company_name'
      }
    }).all()

    const { items, filter, totalCount, options, isSearching, search } = offerLineCollection

    const headers = [
      { text: 'Offertenummer', value: 'offer.offer_number', sortable: false },
      { text: 'Klantnaam', value: 'offer.customer.name', sortable: false },
      { text: 'Omschrijving', value: 'description', sortable: false },
      { text: 'Leverancier', value: 'supplier.company_name', sortable: false },
      { text: 'Aantal', value: 'quantity', sortable: false },
      { text: 'Stuksprijs', value: 'unit_price', sortable: false },
      { text: 'Prijs', value: 'price', sortable: false },
    ]

    const selected = ref([])

    const arrivalDialog = ref(false)

    const offerLineModel = api.resource('offer-lines').model()

    function arrivalSave() {
      const offerLineModels = []
      selected.value.forEach(offerLine => {
        offerLineModel.clear()
        offerLineModel.populate(offerLine)
        offerLineModel.setAttributes({
          offer_line_status_id: OFFER_LINE_STATUS_RECEIVED,
        })
        offerLineModels.push(offerLineModel.save())
      })
      Promise.all(offerLineModels).then(() => {
        offerLineCollection.search()
        arrivalDialog.value = false
        selected.value = []
      })
    }

    
    const searchKeyUp = debounce(offerLineCollection.search, 300)

    return {
      selected,
      headers,
      items,
      filter,
      search,
      totalCount,
      options,
      isSearching,
      arrivalDialog,
      arrivalSave,
      suppliers,
      searchKeyUp,
      formatCurrency,
    }

  },
}
</script>


<style scoped>

::v-deep .v-data-table table tbody tr:not(.v-data-table__empty-wrapper):hover {
  cursor: pointer;
}


</style>
